<template>
  <PageContentLayoutOnly>
    <ModernTable
      property-list-table
      :loading="loading"
      :region="region"
      :rows="rows"
      :columns="columns"
      :pagination="{
        perPage: 10
      }"
      :filters="filters"
      :expandableRows="true"
      :rowOnClick="rowOnClick"
      :_styles="{
        tableWrapper: {
          height: 'calc(100vh - 240px)'
        }
      }"
    >
      <!-- :focusable="true"
      :selected.sync="selectedRow" -->

      <template v-slot:header>
        <div
          :style="{
            display: 'flex',
            justifyContent: 'space-between',

            marginBottom: '20px',

            ...(breakpoint === 'desktop' ? {} : { flexDirection: 'column' })
          }"
        >
          <h3 :style="{ display: 'inline-block', textAlign: 'center' }">
            {{ $t('units.listTitle') }}
          </h3>

          <div v-if="searchedByOther" :style="{ overflow: 'hidden', padding: '12px 0' }">
            <b-taglist
              attached
              v-if="shownEmailFilter"
              :style="{ float: 'right', marginLeft: '12px' }"
            >
              <b-tag type="is-primary">Email Contains</b-tag>
              <b-tag closable aria-close-label="Close tag" @close="clearEmailFilter()">{{
                shownEmailFilter
              }}</b-tag>
            </b-taglist>

            <b-taglist
              attached
              v-if="shownPhoneNumberFilter"
              :style="{ float: 'right', marginLeft: '12px' }"
            >
              <b-tag type="is-primary">Phone Number Contains</b-tag>
              <b-tag closable aria-close-label="Close tag" @close="clearPhoneNumberFilter()">{{
                shownPhoneNumberFilter
              }}</b-tag>
            </b-taglist>
          </div>

          <div :style="{ display: 'flex', justifyContent: 'space-around' }">
            <b-button
              advanced-search-modal-button
              type="is-primary"
              class="is-rounded"
              :style="{}"
              @click="showSearchOptions = true"
            >
              <div style="display: inline-block">Search by other</div>
            </b-button>

            <b-button
              type="is-ghost"
              @click="toggleFilters"
              :style="{}"
              :class="'filter-button ' + (filters.show ? 'active' : '')"
            >
              <b-icon
                pack="mdi"
                icon="filter-outline"
                size="is-small"
                :style="{ display: 'inline-block' }"
              ></b-icon>
              <div :style="{ display: 'inline-block' }">Filter</div>
            </b-button>
          </div>
        </div>
      </template>
    </ModernTable>

    <Modal
      :toggle.sync="showSearchOptions"
      :styles="{
        modal: {
          maxWidth: '600px',
          overflow: 'visible'
        },
        modalContent: {
          overflow: 'visible'
        }
      }"
      advanced-filters
    >
      <div :style="{ paddingBottom: '60px' }">
        <b-field label="Email Address">
          <!-- <b-input v-model="emailFilter" email-filter></b-input> -->
          <b-autocomplete
            email-autocomplete
            v-model="emailFilter"
            :data="filteredEmails"
            field="value"
            @select="option => (selected = option)"
            :open-on-focus="true"
            :clearable="true"
          >
          </b-autocomplete>
        </b-field>

        <b-field label="Phone Number">
          <!-- <b-input v-model="phoneNumberFilter" phone-number-filter></b-input> -->
          <b-autocomplete
            phone-number-autocomplete
            v-model="phoneNumberFilter"
            :data="filteredPhoneNumbers"
            field="value"
            @select="option => (selected = option)"
            :open-on-focus="true"
            :clearable="true"
          >
          </b-autocomplete>
        </b-field>

        <b-button
          advanced-search-button
          type="is-primary"
          :style="{ float: 'right' }"
          @click="searchByOther"
        >
          <div style="display: inline-block">Search</div>
        </b-button>

        <b-button
          advanced-clear-button
          :style="{ marginRight: '12px', float: 'right' }"
          @click="clearOtherFilters()"
        >
          <div style="display: inline-block">Clear</div>
        </b-button>
      </div>
    </Modal>
  </PageContentLayoutOnly>
</template>

<script>
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import ModernTable from '@/components/tables/Modern/Table'
import Modal from '@/components/Modal'
import kms from '@/services/kms'
import { computed } from './keys/computed'
import { data } from './keys/data'
import { methods } from './keys/methods'
import { watch } from './keys/watch'

export default {
  components: { PageContentLayoutOnly, ModernTable, Modal },

  props: {
    kms: {
      default() {
        return kms
      }
    }
  },

  computed,
  data,
  methods,
  watch,

  beforeMount() {
    this.reload()
  },

  i18n: {
    messages: {
      en: { units: { listTitle: 'Properties' } }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/_variables';

.filter-button {
  color: #838383 !important;

  &.active {
    color: $link !important;
  }
}

.button.is-ghost {
  background: none;
  border-color: transparent;
  color: #7957d5;
  text-decoration: none;
}
</style>
