<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="26" viewBox="0 0 32 26">
    <g fill="none" fill-rule="evenodd">
      <g fill="#FFF" fill-rule="nonzero">
        <g>
          <g>
            <path
              d="M38.636 28.636v9.546c0 .345-.128.643-.383.895-.256.252-.56.378-.91.378h-7.757v-7.637h-5.172v7.637h-7.757c-.35 0-.654-.126-.91-.378-.255-.252-.383-.55-.383-.895v-9.546c0-.013.003-.033.01-.06.006-.026.01-.046.01-.059L27 19.091l11.616 9.426c.014.027.02.066.02.12zm4.223-1.085l-1.23 1.497c-.107.121-.246.196-.418.222h-.06c-.171 0-.31-.047-.416-.141L27 17.459l-13.735 11.67c-.159.108-.318.155-.476.141-.172-.026-.311-.1-.417-.222l-1.23-1.497c-.107-.135-.153-.293-.14-.475.013-.182.086-.327.219-.435l14.27-12.115c.424-.35.927-.526 1.509-.526.582 0 1.085.175 1.508.526l4.843 4.126v-3.944c0-.189.06-.344.18-.465.118-.122.27-.182.456-.182h3.81c.186 0 .338.06.457.182.12.121.179.276.179.465v8.252l4.346 3.681c.133.108.206.253.219.435.013.182-.033.34-.14.475z"
              transform="translate(-1025 -544) translate(987 512) translate(27 18)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
/*
	import HouseFill from '@/components/icons/House-Fill'
*/
export default {
  props: {
    stroke: {
      default: '#FFF',
      type: String
    }
  }
}
</script>
