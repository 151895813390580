import _get from 'lodash/get'
import Actions from './../components/Actions'
import { parseDate } from '@/utilities/Date/parse'
import { parseE164 } from '@/utilities/phoneNumber/parseE164'

export const watch = {
  hoaId() {
    this.getRefreshed()
  },

  ownerContactList: function() {
    const component = this

    const dateFormat = 'LL'

    this.rows = this.ownerContactList.map(contact => {
      const id = _get(contact, 'ownerContactID', null)
      const firstName = _get(contact, 'firstName', '')
      const lastName = _get(contact, 'lastName', '')
      const name = `${firstName} ${lastName}`
      const primaryContact = _get(contact, 'isPrimaryContact', false)
      const isPrimaryContact = primaryContact && primaryContact === true ? 'Yes' : 'No'

      return {
        firstName: firstName,
        lastName: lastName,
        emailAddressOne: _get(contact, 'emailAddressOne', ''),
        phone: parseE164(_get(contact, 'phone', null)),
        cellPhone: parseE164(_get(contact, 'cellPhone', null)),
        workPhone: parseE164(_get(contact, 'workPhone', null)),
        isPrimaryContact,
        modifiedDate: {
          date: parseDate(_get(contact, 'modifiedDate', null), dateFormat),
          format: dateFormat
        },
        actions: {
          component: Actions,
          props: {
            confirmDelete: (_id, _firstName, _lastName) => {
              this.$buefy.dialog.confirm({
                title: 'Deleting Contact',
                message: `Are you sure you want to <b>delete</b> this Contact: '${_firstName ||
                  ''} ${_lastName || ''}'?`,
                confirmText: 'Delete Contact',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => this.deleteRecord(_id)
              })
            },
            ownerContactID: id,
            name: name,
            loadUpdateOwnerContact: _id => {
              component.selectedID = _id
              component.formShow = true
              component.action = 'update'

              this.updateFormData(contact)
            }
          }
        }
      }
    })

    this.tableLoading = false
  }
}
