var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageContent',[_c('div',{staticClass:"level"},[_c('div',{staticClass:"level-left level-item title"}),_c('div',{staticClass:"level-right"},[_c('div',{staticClass:"level-item"},[_c('svg',{attrs:{"width":"30","height":"30","xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M15 19l-7-7 7-7"}})]),_c('b-button',{attrs:{"label":"Back to Properties"},on:{"click":function($event){$event.preventDefault();return _vm.returnToParent()}}})],1)])]),(_vm.unit && _vm.breakpoint === 'desktop')?_c('section',{staticClass:"section card",style:(Object.assign({}, {overflow: 'visible',
      marginTop: '10px'},
      (['tablet', 'mobile'].includes(_vm.breakpoint)
        ? {
            padding: 0
          }
        : {})))},[_c('div',{staticClass:"details-top-container",style:({
        display: 'flex',
        width: '100%',
        height: '600px',
        paddingTop: '12px',
        borderBottom: '1px solid #dcdcdc'
      }),attrs:{"desktop-perspective":""}},[_c('div',{style:({
          display: 'flex',
          width: '16%',
          height: '100%',
          maxWidth: '150px',
          minWidth: '90px'
        }),attrs:{"role":"region","tabindex":"0","aria-label":"property photo gallery"}},[_c('Gallery',{ref:"Gallery",attrs:{"openUploadModal":_vm.openUploadModal,"updatePhotoDisplay":_vm.updatePhotoDisplay,"updateOccurred":_vm.galleryUpdateOccurred,"unitID":_vm.unitID,"editMode":_vm.editMode,"photos":_vm.photos,"loading":_vm.gallery.loading}})],1),_c('div',{style:({
          display: 'flex',
          width: '100%',
          paddingLeft: '10px',
          height: '100%',
          flexWrap: 'wrap'
        })},[_c('div',{staticClass:"card",style:({ height: '80%', width: '59%', marginRight: '1%' })},[_c('PrimaryPhoto',{attrs:{"src":_vm.primaryPhoto,"showIcon":false}})],1),_c('div',{staticClass:"card",style:({ height: '80%', width: '40%', overflowY: 'auto' })},[_c('ContactDetails',{attrs:{"role":"region","tabindex":"0","aria-label":"contact details of the primary owner","currentOwner":_vm.currentOwner,"primaryOwnerID":_vm.unit.primaryOwnerID,"primaryContact":_vm.primaryContact,"redirectToCurrentOwner":_vm.redirectToCurrentOwner,"unitID":_vm.unitID,"lotNumber":_vm.lotNumber}})],1),_c('div',{style:({
            height: '20%',
            width: '100%'
          })},[_c('Address',{attrs:{"address":_vm.unit.address,"unitID":_vm.unitID}})],1)])]),_c('div',{attrs:{"id":"tabs"}},[_c('div',{staticClass:"card-content columns",style:(_vm.styles.mainContainer)},[(_vm.breakpoint === 'desktop')?_c('div',{staticClass:"column is-2"},[_c('DetailsMenu',{attrs:{"aria-label":"property details tabs","tabs":_vm.tabs,"prefix":"unit.","params":{
              id: _vm.$route.params.id,
              ownerID: _vm.ownerID
            }}})],1):_vm._e(),_c('div',{staticClass:"column is-10",style:({ overflow: 'hidden', position: 'relative' })},[_c('SlideTransition',{attrs:{"name":_vm.transitionName}},[_c('router-view')],1)],1)])])]):_vm._e(),_c('div',[(_vm.unit && ['tablet', 'mobile'].includes(_vm.breakpoint))?_c('section',{staticClass:"section",style:({
        overflow: 'visible',
        marginTop: '10px',
        padding: 0
      })},[_c('div',{staticClass:"card",attrs:{"mobile-tablet-perspective":""}},[_c('PrimaryPhoto',{attrs:{"src":_vm.primaryPhoto,"size":'300px'}}),_c('MobileGallery',{ref:"MobileGallery",attrs:{"openUploadModal":_vm.openUploadModal,"updatePhotoDisplay":_vm.updatePhotoDisplay,"updateOccurred":_vm.galleryUpdateOccurred,"unitID":_vm.unitID,"editMode":_vm.editMode,"photos":_vm.photos,"loading":_vm.gallery.loading}}),_c('div',{style:({
            height: '20%',
            width: '100%'
          })},[_c('Address',{attrs:{"address":_vm.unit.address,"unitID":_vm.unitID}}),_c('div',{style:({
              background: 'rgb(220, 220, 220)',

              margin: '0 12px',
              height: '1px',
              width: 'calc(100% - 24px)'
            })}),_c('ContactDetailsMobile',{attrs:{"role":"region","tabindex":"0","aria-label":"contact details of the primary owner","currentOwner":_vm.currentOwner,"primaryContact":_vm.primaryContact,"unitID":_vm.unitID,"editMode":_vm.editMode,"lotNumber":_vm.lotNumber},on:{"update:editMode":function($event){_vm.editMode=$event},"update:edit-mode":function($event){_vm.editMode=$event}}}),_c('div',{style:({
              background: 'rgb(220, 220, 220)',

              margin: '0 12px',
              height: '1px',
              width: 'calc(100% - 24px)'
            })})],1)],1),_c('div',{staticClass:"card",style:({
          overflow: 'visible',
          position: 'relative',
          marginTop: '40px',
          padding: '20px'
        }),attrs:{"id":"tabs"}},[_c('div',{style:({
            background: _vm.palette[1],
            borderRadius: '50%',

            position: 'absolute',
            top: '-20px',
            left: '50%',
            transform: 'translateX(-50%)',

            height: '55px',
            width: '55px'
          })},[_c('div',{style:({
              background: _vm.palette[2],
              borderRadius: '50%',

              position: 'relative',
              top: '5px',
              left: '5px',

              height: 'calc(100% - 10px)',
              width: 'calc(100% - 10px)'
            })},[_c('HouseFill',{style:({
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translateX(-50%) translateY(-50%) scale(1.2)'
              })})],1)]),(_vm.breakpoint === 'desktop')?_c('div',{staticClass:"column is-2"},[_c('DetailsMenu',{attrs:{"aria-label":"property details tabs","tabs":_vm.tabs,"prefix":"unit.","params":{
              id: _vm.$route.params.id,
              ownerID: _vm.ownerID
            }}})],1):_vm._e(),_c('div',{style:({ overflow: 'hidden', position: 'relative', padding: '2em 20px 2em' })},[(['tablet', 'mobile'].includes(_vm.breakpoint))?_c('b-field',[_c('b-select',{style:({ maxWidth: '300px', width: '100%', margin: '0 auto' }),attrs:{"rounded":""},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},_vm._l((_vm.tabs),function(key,index){return _c('option',{key:index},[_vm._v(" "+_vm._s(key.label)+" ")])}),0)],1):_vm._e()],1),_c('div',{style:({ overflow: 'hidden', position: 'relative', minHeight: '600px' })},[_c('SlideTransition',{attrs:{"name":_vm.transitionName}},[_c('router-view')],1)],1)]),_c('b-loading',{attrs:{"is-full-page":false},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}})],1):_vm._e()]),_c('UploadModal',{attrs:{"toggle":_vm.showUploadModal,"unitID":_vm.unitID,"uploadComplete":_vm.uploadComplete},on:{"update:toggle":function($event){_vm.showUploadModal=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }