<template>
  <div role="main" aria-label="Account Activity">
    <account-activity
      :transactions="ownerAccountTransactions"
      :chargeTypeBalances="ownerAccountChargeTypeBalances"
      :balance="ownerAccountBalance"
      :loading="loading"
      @filterChange="onFilterChange"
    />
  </div>
</template>

<script>
import AccountActivity from './components/AccountActivity'

import { mapState } from 'vuex'
import _get from 'lodash/get'

export default {
  components: { AccountActivity },
  computed: {
    ...mapState('hoa', ['unit', 'ownerAccountChargeTypeBalances'])
  },

  data: () => ({
    loading: true,
    filters: {},
    ownerAccountTransactions: [],
    ownerAccountBalance: null
  }),

  mounted() {
    this.unsubscribe = this.$store.subscribe(mutation => {
      if (mutation.type === 'hoa/save') {
        this.ownerAccountTransactions = _get(mutation, ['payload', 'ownerAccountTransactions'], [])
        this.ownerAccountBalance = _get(mutation, ['payload', 'ownerAccountBalance'], null)

        this.loading = false
      }
    })

    this.$store.dispatch('hoa/loadOwnerAccountTransactions', {
      ownerID: _get(this, ['unit', 'primaryOwner', 'ownerID'], null),
      startDate: null,
      endDate: null,
      showWaiverSummaries: true
    })
  },

  beforeDestroy() {
    this.unsubscribe()
  },

  watch: {
    unit: 'refresh',
    filters: 'refresh'
  },

  methods: {
    onFilterChange(filters) {
      this.filters = { ...this.filters, ...filters }
    },

    refresh() {
      this.loading = true

      const ownerID = _get(this, ['unit', 'primaryOwner', 'ownerID'], null)

      const fromDate = _get(this, ['filters', 'fromDate'], null)
      const toDate = _get(this, ['filters', 'toDate'], null)

      this.$store.dispatch('hoa/loadOwnerAccountTransactions', {
        ownerID,
        startDate: fromDate,
        endDate: toDate,
        showWaiverSummaries: true
      })
    }
  }
}
</script>
