<template>
  <span style="white-space:nowrap !important;">
    <button
      class="button is-info is-light is-small is-outline is-rounded"
      @click="loadUpdateOwnerContact(ownerContactID)"
    >
      Edit</button
    >&nbsp;
    <button
      class="button is-info is-light is-small is-outline is-rounded"
      @click="confirmDelete(ownerContactID, name)"
    >
      Delete
    </button>
  </span>
</template>

<script>
export default {
  props: ['confirmDelete', 'ownerContactID', 'name', 'loadUpdateOwnerContact']
}
</script>
