<template>
  <div class="card-content has-text-centered" contact-details>
    <figure class="image is-128x128 owner-avatar">
      <img
        src="@/assets/images/default-user.jpg"
        alt="Default Image"
        class="is-rounded avatar-img"
      />
    </figure>

    <h3 :title="`Owner ID: ${currentOwnerParsed.id}`">
      {{ currentOwnerParsed.name || '' }}
    </h3>

    <ul v-if="lotNumber">
      <li>
        <label class="h6 is-inline">Lot Number: </label>
        <span>{{ lotNumber }}</span>
      </li>
    </ul>

    <ul v-if="primaryContactParsed">
      <li v-if="primaryContactParsed.phone">
        <label class="h6 is-inline">Phone: </label>
        <a :href="`tel:${primaryContactParsed.phone}`">{{ primaryContactParsed.phone }}</a>
      </li>

      <li v-if="primaryContactParsed.cellPhone">
        <label class="h6 is-inline">Cell: </label>
        <a :href="`tel:${primaryContactParsed.cellPhone}`">{{ primaryContactParsed.cellPhone }}</a>
      </li>

      <li v-if="primaryContactParsed.workPhone">
        <label class="h6 is-inline">Work: </label>
        <a :href="`tel:${primaryContactParsed.workPhone}`">{{ primaryContactParsed.workPhone }}</a>
      </li>

      <li v-if="primaryContactParsed.emailAddressOne">
        <label class="h6 is-inline">Email: </label>
        <a :href="`mailto:${primaryContactParsed.emailAddressOne}`">{{
          primaryContactParsed.emailAddressOne
        }}</a>
      </li>

      <li v-if="primaryContactParsed.emailAddressTwo">
        <label class="h6 is-inline">Secondary Email: </label>
        <a :href="`mailto:${primaryContactParsed.emailAddressTwo}`">{{
          primaryContactParsed.emailAddressTwo
        }}</a>
      </li>

      <li v-if="currentOwnerParsed.settlementDate">
        <label class="h6 is-inline">Owned Since: </label>
        <a href="#" @click.stop>{{ currentOwnerParsed.settlementDate | date }}</a>
      </li>

      <li v-if="currentOwnerParsed.moveOutDate">
        <label class="h6 is-inline">Move-out Date: </label>
        <a href="#" @click.stop>{{ currentOwnerParsed.moveOutDate | date }}</a>
      </li>

      <button
        v-if="currentOwnerParsed.moveOutDate"
        :style="{ display: 'inline-block', margin: '0 0 0 8px', borderRadius: '6px' }"
        class="subheading button is-primary is-small is-outlined"
        @click="redirectToCurrentOwner(primaryOwnerID)"
      >
        View Current Owner
      </button>
    </ul>
  </div>
</template>

<script>
import _get from 'lodash/get'
import { parseE164 } from '@/utilities/phoneNumber/parseE164'

export default {
  props: {
    primaryContact: {
      type: Object,
      default: () => {
        return {}
      }
    },
    currentOwner: {
      type: Object,
      default: () => {
        return {}
      }
    },
    unitID: [String, Number],
    primaryOwnerID: Number,
    lotNumber: {
      type: String,
      default: null
    },
    editMode: Boolean,
    redirectToCurrentOwner: Function
  },
  computed: {
    primaryContactParsed() {
      const primaryContact = this.primaryContact

      return {
        phone: parseE164(_get(primaryContact, 'phone', '')),
        cellPhone: parseE164(_get(primaryContact, 'cellPhone', '')),
        workPhone: parseE164(_get(primaryContact, 'workPhone', '')),
        emailAddressOne: _get(primaryContact, 'emailAddressOne', ''),
        emailAddressTwo: _get(primaryContact, 'emailAddressTwo', '')
      }
    },
    currentOwnerParsed() {
      console.debug('in currentOwnerParsed...')
      const currentOwner = this.currentOwner

      console.debug('currentOwnerParsed=' + JSON.stringify(currentOwner))

      return {
        name: _get(currentOwner, 'name', ''),
        id: _get(currentOwner, 'ownerID', ''),
        settlementDate: _get(currentOwner, 'settlementDate', ''),
        moveOutDate: _get(currentOwner, 'moveOutDate', '')
      }
    }
  },
  data() {
    return {
      editModeData: this.editMode
    }
  },
  methods: {
    showEdit() {
      this.editModeData = !this.editModeData

      if (this.editModeData) {
        this.$emit('update:editMode', true)
      } else {
        this.$emit('update:editMode', false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/_variables';

.icon-container {
  top: 10px;
  right: 10px;

  height: 40px;
  width: 40px;

  .icon-pencil {
    height: 100%;
    width: 100%;
  }

  .icon-x {
    height: 50%;
    width: 50%;
  }

  &:focus {
    border-radius: 6px;
    box-shadow: $tabable-focus;
  }
}

.owner-avatar {
  margin: 1em auto 2em;
}

.avatar-img {
  border: 1px solid #e7e8f2;
  padding: 0.25em;
}
</style>
