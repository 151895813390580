var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageContentLayoutOnly',[_c('ModernTable',{attrs:{"property-list-table":"","loading":_vm.loading,"region":_vm.region,"rows":_vm.rows,"columns":_vm.columns,"pagination":{
      perPage: 10
    },"filters":_vm.filters,"expandableRows":true,"rowOnClick":_vm.rowOnClick,"_styles":{
      tableWrapper: {
        height: 'calc(100vh - 240px)'
      }
    }},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{style:(Object.assign({}, {display: 'flex',
          justifyContent: 'space-between',

          marginBottom: '20px'},

          (_vm.breakpoint === 'desktop' ? {} : { flexDirection: 'column' })))},[_c('h3',{style:({ display: 'inline-block', textAlign: 'center' })},[_vm._v(" "+_vm._s(_vm.$t('units.listTitle'))+" ")]),(_vm.searchedByOther)?_c('div',{style:({ overflow: 'hidden', padding: '12px 0' })},[(_vm.shownEmailFilter)?_c('b-taglist',{style:({ float: 'right', marginLeft: '12px' }),attrs:{"attached":""}},[_c('b-tag',{attrs:{"type":"is-primary"}},[_vm._v("Email Contains")]),_c('b-tag',{attrs:{"closable":"","aria-close-label":"Close tag"},on:{"close":function($event){return _vm.clearEmailFilter()}}},[_vm._v(_vm._s(_vm.shownEmailFilter))])],1):_vm._e(),(_vm.shownPhoneNumberFilter)?_c('b-taglist',{style:({ float: 'right', marginLeft: '12px' }),attrs:{"attached":""}},[_c('b-tag',{attrs:{"type":"is-primary"}},[_vm._v("Phone Number Contains")]),_c('b-tag',{attrs:{"closable":"","aria-close-label":"Close tag"},on:{"close":function($event){return _vm.clearPhoneNumberFilter()}}},[_vm._v(_vm._s(_vm.shownPhoneNumberFilter))])],1):_vm._e()],1):_vm._e(),_c('div',{style:({ display: 'flex', justifyContent: 'space-around' })},[_c('b-button',{staticClass:"is-rounded",style:({}),attrs:{"advanced-search-modal-button":"","type":"is-primary"},on:{"click":function($event){_vm.showSearchOptions = true}}},[_c('div',{staticStyle:{"display":"inline-block"}},[_vm._v("Search by other")])]),_c('b-button',{class:'filter-button ' + (_vm.filters.show ? 'active' : ''),style:({}),attrs:{"type":"is-ghost"},on:{"click":_vm.toggleFilters}},[_c('b-icon',{style:({ display: 'inline-block' }),attrs:{"pack":"mdi","icon":"filter-outline","size":"is-small"}}),_c('div',{style:({ display: 'inline-block' })},[_vm._v("Filter")])],1)],1)])]},proxy:true}])}),_c('Modal',{attrs:{"toggle":_vm.showSearchOptions,"styles":{
      modal: {
        maxWidth: '600px',
        overflow: 'visible'
      },
      modalContent: {
        overflow: 'visible'
      }
    },"advanced-filters":""},on:{"update:toggle":function($event){_vm.showSearchOptions=$event}}},[_c('div',{style:({ paddingBottom: '60px' })},[_c('b-field',{attrs:{"label":"Email Address"}},[_c('b-autocomplete',{attrs:{"email-autocomplete":"","data":_vm.filteredEmails,"field":"value","open-on-focus":true,"clearable":true},on:{"select":function (option) { return (_vm.selected = option); }},model:{value:(_vm.emailFilter),callback:function ($$v) {_vm.emailFilter=$$v},expression:"emailFilter"}})],1),_c('b-field',{attrs:{"label":"Phone Number"}},[_c('b-autocomplete',{attrs:{"phone-number-autocomplete":"","data":_vm.filteredPhoneNumbers,"field":"value","open-on-focus":true,"clearable":true},on:{"select":function (option) { return (_vm.selected = option); }},model:{value:(_vm.phoneNumberFilter),callback:function ($$v) {_vm.phoneNumberFilter=$$v},expression:"phoneNumberFilter"}})],1),_c('b-button',{style:({ float: 'right' }),attrs:{"advanced-search-button":"","type":"is-primary"},on:{"click":_vm.searchByOther}},[_c('div',{staticStyle:{"display":"inline-block"}},[_vm._v("Search")])]),_c('b-button',{style:({ marginRight: '12px', float: 'right' }),attrs:{"advanced-clear-button":""},on:{"click":function($event){return _vm.clearOtherFilters()}}},[_c('div',{staticStyle:{"display":"inline-block"}},[_vm._v("Clear")])])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }