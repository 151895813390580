<template>
  <div>
    <h4 :style="{ margin: '0 10px' }">Current Owners</h4>
    <OwnerContacts :contacts="contacts" />
  </div>
</template>

<script>
import _get from 'lodash/get'

import OwnerContacts from '@/components/OwnerContacts'

import { parseOwnerContacts } from '@/services/Roster/Owner/parseOwnerContacts'

export default {
  components: {
    OwnerContacts
  },
  props: {
    ownerContacts: Array
  },
  data() {
    const theme = _get(this, ['$store', 'state', 'theme'], {})

    return {
      contacts: parseOwnerContacts({
        contacts: this.ownerContacts
      }),
      color: theme.color
    }
  },
  watch: {
    ownerContacts() {
      this.contacts = parseOwnerContacts({
        contacts: this.ownerContacts
      })
    }
  }
}
</script>
