import kms from '@/services/kms'
import { notifyMessage, notifyProblem } from '@/services/notify'
import _get from 'lodash/get'

export async function addOwnerContact() {
  if (this.isDebug == true) console.debug('in add ownerID=' + this.ownerID)

  const ownerID = _get(this, ['ownerID'], undefined)
  const formData = _get(this, ['formData'], undefined)
  formData.ownerContactID = 0

  if (typeof ownerID === 'number' && ownerID > 0 && formData !== undefined) {
    try {
      let validationMessages = ''
      if (this.formData.firstName === '' || this.formData.firstName.length === 0) {
        validationMessages = 'First Name is required.'
      }

      if (this.formData.lastName === '') {
        validationMessages = validationMessages + ' Last Name is required.'
      }

      if (validationMessages !== '') {
        notifyProblem(validationMessages)
        return
      }

      this.loading = true

      const formData = this.parseFormData()
      console.debug('formData===' + JSON.stringify(formData))
      const results = await kms.post(`/Roster/OwnerContact`, formData)

      console.debug('owner contact results=' + JSON.stringify(results))

      if (results.ownerContactID && results.ownerContactID > 0) {
        if (
          results.isPrimaryContact !== undefined &&
          results.isPrimaryContact &&
          results.isPrimaryContact === true
        ) {
          this.reRender()
        } else {
          this.resetForm()
          this.getRefreshed()
        }
        notifyMessage(`Successfully added contact.`)
        this.showForm = false
      } else {
        notifyProblem('There was a problem adding the new contact.')
      }
    } catch (exception) {
      this.showFormValidationErrors({
        exception
      })
    }
  }

  this.loading = false
}
