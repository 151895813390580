import moment from 'moment'

export const data = function() {
  let DEFAULT_FORM_VALUES = {
    ownerContactID: 0,
    firstName: '',
    lastName: '',
    emailAddressOne: '',
    emailAddressTwo: '',
    emailAddressThree: '',
    homePhone: '',
    cellPhone: '',
    workPhone: '',
    isPrimaryContact: false
  }

  let DEFAULT_FORM_ERRORS = {
    firstName: '',
    lastName: '',
    emailAddressOne: '',
    emailAddressTwo: '',
    emailAddressThree: '',
    homePhone: '',
    cellPhone: '',
    workPhone: '',
    isPrimaryContact: false
  }

  return {
    // errorMessages: [],
    DEFAULT_FORM_VALUES,
    formData: {
      ...DEFAULT_FORM_VALUES
    },

    // server error messages
    formErrors: {
      ...DEFAULT_FORM_ERRORS
    },

    tableLoading: true,
    isDebug: true,
    confirmModal: false,
    formatDateFn: function(value) {
      return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
    },
    formatFn: function(value) {
      return value != null ? value : ''
    },
    formatNumberFn: function(value) {
      return value != null ? value : 0
    },
    selectedRow: null,
    ownerContactList: [{}],
    ownerID: null,
    selectedID: null,
    selectedOwnerContact: '',
    formShow: false,
    action: '',
    hasPermission: false,
    isFetching: false,
    keepFirst: true,
    selected: null,
    openOnFocus: true,

    showForm: false,

    additionErrors: {},

    // table data
    rows: [],
    columns: [
      {
        field: 'firstName',
        label: 'First Name',
        aria: 'First Name',
        width: '10%',
        sortable: true,
        searchable: true
      },
      {
        field: 'lastName',
        label: 'Last Name',
        aria: 'Last Name',
        width: '10%',
        sortable: true,
        searchable: true
      },
      {
        field: 'emailAddressOne',
        label: 'Email Address One',
        aria: 'Email Address One',
        width: '10%',
        sortable: true,
        searchable: true
      },
      {
        field: 'phone',
        label: 'Home Phone',
        aria: 'Home Phone',
        width: '10%',
        sortable: true,
        searchable: true
      },
      {
        field: 'cellPhone',
        label: 'Cell Phone',
        aria: 'Cell Phone',
        width: '10%',
        sortable: true,
        searchable: true
      },
      {
        field: 'workPhone',
        label: 'Work Phone',
        aria: 'Work Phone',
        width: '10%',
        sortable: true,
        searchable: true
      },
      {
        field: 'isPrimaryContact',
        label: 'Primary Contact',
        aria: 'Primary Contact',
        width: '10%',
        sortable: true,
        searchable: true
      },
      {
        field: 'modifiedDate',
        date: true,
        label: 'Modified Date',
        aria: 'Modified Date',
        width: '5%',
        sortable: true,
        searchable: true
      },
      {
        field: 'actions',
        label: 'Actions',
        width: '10%'
      }
    ],
    region: {},
    filters: {
      show: false
    }
  }
}
