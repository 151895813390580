import kms from '@/services/kms'
import { notifyMessage, notifyProblem } from '@/services/notify'
import _get from 'lodash/get'

export async function onFormSubmit() {
  if (this.isDebug == true) console.debug('in add ownerID=' + this.ownerID)

  const ownerID = _get(this, ['ownerID'], undefined)
  const formData = _get(this, ['formData'], undefined)
  formData.emergencyContactID = 0

  if (typeof ownerID === 'number' && ownerID > 0 && formData !== undefined) {
    try {
      this.loading = true

      const formData = this.parseFormData()
      const results = await kms.post(`/Roster/EmergencyContact`, formData)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (results.emergencyContactID && results.emergencyContactID > 0) {
        this.resetForm()
        this.getRefreshed()
        notifyMessage(`Successfully added emergency contact.`)
        this.showForm = false
      } else {
        notifyProblem('There was a problem adding the new emergency contact.')
      }
    } catch (exception) {
      this.showFormValidationErrors({
        exception
      })
    }
  }

  this.loading = false
}
