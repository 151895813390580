import _get from 'lodash/get'
import Actions from './../components/Actions'
import { parseDate } from '@/utilities/Date/parse'
import parseAddress from '@/utilities/address/parse'
import { parseE164 } from '@/utilities/phoneNumber/parseE164'

export const watch = {
  hoaId() {
    this.getRefreshed()
  },

  emergencyContactList: function() {
    const component = this

    const dateFormat = 'LL'

    this.rows = this.emergencyContactList.map(contact => {
      const id = _get(contact, 'emergencyContactID', null)
      const name = _get(contact, 'name', null)

      return {
        name: _get(contact, 'name', null),
        address: parseAddress({
          address: _get(contact, 'address', null)
        }),

        homePhone: parseE164(_get(contact, 'homePhone', null)),
        cellPhone: parseE164(_get(contact, 'cellPhone', null)),
        homeEmail: _get(contact, 'homeEmail', null),
        modifiedDate: {
          date: parseDate(_get(contact, 'modifiedDate', null), dateFormat),
          format: dateFormat
        },
        actions: {
          component: Actions,
          props: {
            confirmDelete: (_id, _name) => {
              this.$buefy.dialog.confirm({
                title: 'Deleting Emergency Contact',
                message: `Are you sure you want to <b>delete</b> this Emergency Contact: '${_name}'?`,
                confirmText: 'Delete Emergency Contact',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => this.deleteRecord(_id)
              })
            },
            emergencyContactID: id,
            name: name,
            loadUpdateEmergencyContact: _id => {
              component.selectedID = _id
              component.formShow = true
              component.action = 'update'

              this.updateFormData(contact)
            }
          }
        }
      }
    })

    this.tableLoading = false
  }
}
