/*
  Example:
    // from a partial date, returns a complete date

    import { completeDate } from '@/utilities/Date/complete'

    const { fromDate, toDate } = completeDate ({
      from,
      to
    });
*/
import moment from 'moment'

import _get from 'lodash/get'

export const completeDate = ({ from = null, to = null }) => {
  var fromDate = null

  const fromYear = _get(from, 'year', '')
  if (typeof fromYear === 'string' && fromYear.length > 0) {
    const year = fromYear
    const month = from.month || 'January'
    const dayOfMonth = from.day || '1'

    fromDate = `${month} ${dayOfMonth}, ${year}`
  }

  var toDate = null
  const toYear = _get(to, 'year', '')
  if (typeof toYear === 'string' && toYear.length > 0) {
    const year = toYear
    const month = to.month || 'December'

    const monthFormatted = moment()
      .month(month)
      .format('MM')

    // sets date as the last day of the month
    const dayOfMonth = to.day || moment(`${toYear}-${monthFormatted}`, 'YYYY-MM').daysInMonth()

    toDate = `${month} ${dayOfMonth}, ${year}`
  }

  return {
    fromDate,
    toDate
  }
}
