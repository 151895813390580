import moment from 'moment'

export const data = function() {
  let DEFAULT_FORM_VALUES = {
    emergencyContactsID: 0,
    addressID: 0,
    address1: '',
    address2: '',
    address3: '',
    city: '',
    region: '',
    postalCode: '',
    foreignAddress: false,
    name: '',
    homePhone: '',
    cellPhone: '',
    homeEmail: ''
  }

  let DEFAULT_FORM_ERRORS = {
    address1: '',
    address2: '',
    address3: '',
    city: '',
    region: '',
    postalCode: '',
    name: '',
    homePhone: '',
    cellPhone: '',
    homeEmail: ''
  }

  return {
    // errorMessages: [],
    DEFAULT_FORM_VALUES,
    formData: {
      ...DEFAULT_FORM_VALUES
    },

    // server error messages
    formErrors: {
      ...DEFAULT_FORM_ERRORS
    },

    tableLoading: true,
    isDebug: true,
    confirmModal: false,
    formatDateFn: function(value) {
      return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
    },
    formatFn: function(value) {
      return value != null ? value : ''
    },
    formatNumberFn: function(value) {
      return value != null ? value : 0
    },
    selectedRow: null,
    emergencyContactList: [{}],
    ownerID: null,
    selectedID: null,
    selectedAddressID: null,
    selectedEmergencyContact: '',
    formShow: false,
    action: '',
    hasPermission: false,
    isFetching: false,
    keepFirst: true,
    addressFilter: null,
    selected: null,
    openOnFocus: true,
    foreignAddress: false,
    showForm: false,

    additionErrors: {},

    // table data
    rows: [],
    columns: [
      {
        field: 'name',
        label: 'Name',
        aria: 'Name',
        width: '10%',
        sortable: true,
        searchable: true
      },
      {
        field: 'address',
        label: 'Address',
        aria: 'Address',
        width: '10%',
        sortable: true,
        searchable: true
      },
      {
        field: 'homePhone',
        label: 'Home Phone',
        aria: 'Home Phone',
        width: '15%',
        sortable: true,
        searchable: true
      },
      {
        field: 'cellPhone',
        label: 'Cell Phone',
        aria: 'Cell Phone',
        width: '15%',
        sortable: true,
        searchable: true
      },
      {
        field: 'homeEmail',
        label: 'Home Email',
        aria: 'Home Email',
        width: '15%',
        sortable: true,
        searchable: true
      },
      {
        field: 'modifiedDate',
        date: true,
        label: 'Modified Date',
        aria: 'Modified Date',
        width: '5%',
        sortable: true,
        searchable: true
      },
      {
        field: 'actions',
        label: 'Actions',
        width: '10%'
      }
    ],
    region: {},
    filters: {
      show: false
    }
  }
}
