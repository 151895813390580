import _get from 'lodash/get'
import _cloneDeep from 'lodash/cloneDeep'
import _isPlainObject from 'lodash/isPlainObject'
import parseAddress from '@/utilities/address/parse'
import { stringContains } from '@/utilities/String/contains'
import { notifyProblem } from '@/services/notify'
import SimpleButton from '@/components/buttons/Simple'
import Details from './../components/Details'

export const methods = {
  parseRows({ units = null } = {}) {
    if (!_isPlainObject(units)) {
      units = this.units
    }

    this.rows = units.map(unit => {
      const parsedAddress = parseAddress({
        address: _get(unit, 'address', null)
      })

      return {
        _expandable: {
          // expanded: false,

          // whether or not to show the expandable icon
          show: true,

          // the component to show when the expandable icon is pressed
          component: Details,

          // props to send to the expandable icon
          props: {
            ownerContacts: _get(unit, ['primaryOwner', 'ownerContacts'], [])
          }
        },
        lotNumber: _get(unit, 'lotNumber', ''),
        name: _get(unit, 'primaryOwner.name', ''),
        address: parsedAddress,
        button: {
          component: SimpleButton,
          props: {
            to: {
              name: 'unit.overview',
              params: {
                id: _get(unit, 'unitID', ''),
                ownerid: _get(unit, 'primaryOwnerID', ''),
                previousownerid: _get(unit, 'primaryOwnerID', '')
              }
            },
            text: 'Details'
          }
        }
      }
    })
  },

  clearOtherFilters() {
    this.emailFilter = ''
    this.phoneNumberFilter = ''

    this.units = _cloneDeep(this.allUnits)
    this.searchByOther({
      clear: true
    })
  },
  clearEmailFilter() {
    this.emailFilter = ''
    this.shownEmailFilter = ''

    this.units = _cloneDeep(this.allUnits)
    this.searchByOther({
      clear: true
    })
  },
  clearPhoneNumberFilter() {
    this.phoneNumberFilter = ''
    this.shownPhoneNumberFilter = ''

    this.units = _cloneDeep(this.allUnits)
    this.searchByOther({
      clear: true
    })
  },

  async loadUnits() {
    const hoaID = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null)

    try {
      const { results: units } = await this.kms.get('/Roster/Unit/List', {
        params: {
          hoaID,
          includeOwnerContacts: true,
          startRecord: 1,
          recordCount: 10000
        }
      })

      this.allUnits = _cloneDeep(units)
      this.units = units

      this.parseRows()
    } catch (e) {
      notifyProblem(e)
    }
  },

  rowOnClick() {},

  searchByOther({ clear = false } = {}) {
    if (clear === false) {
      this.searchedByOther = true
    }

    const emailFilter = this.emailFilter
    const phoneNumberFilter = this.phoneNumberFilter

    this.shownEmailFilter = this.emailFilter
    this.shownPhoneNumberFilter = this.phoneNumberFilter

    const { properties } = this.getFilteredProperties({
      units: this.units,
      emailFilter,
      phoneNumberFilter
    })

    this.units = properties

    this.parseRows()

    this.showSearchOptions = false
  },

  getFilteredProperties({ units: _units, emailFilter, phoneNumberFilter }) {
    const units = _cloneDeep(_units)

    const properties = units.filter(unit => {
      const ownerContacts = _get(unit, ['primaryOwner', 'ownerContacts'], [])
      if (!Array.isArray(ownerContacts)) {
        return false
      }
      if (ownerContacts.length === 0) {
        return false
      }

      // if neither filter is specified, return all
      if (emailFilter.length === 0 && phoneNumberFilter.length === 0) {
        return true
      }

      let matchFound = false
      for (let a = 0; a < ownerContacts.length; a++) {
        const ownerContact = ownerContacts[a]

        const emailOne = _get(ownerContact, ['emailAddressOne'], null)
        const emailTwo = _get(ownerContact, ['emailAddressTwo'], null)
        const emailThree = _get(ownerContact, ['emailAddressThree'], null)

        const phone = _get(ownerContact, ['phone'], null)
        const workPhone = _get(ownerContact, ['workPhone'], null)
        const cellPhone = _get(ownerContact, ['cellPhone'], null)

        if (emailFilter.length >= 1) {
          if (
            stringContains(emailOne, emailFilter, { ignoreCase: true }) ||
            stringContains(emailTwo, emailFilter, { ignoreCase: true }) ||
            stringContains(emailThree, emailFilter, { ignoreCase: true })
          ) {
            matchFound = true
            break
          }
        }

        if (phoneNumberFilter.length >= 1) {
          if (
            stringContains(phone, phoneNumberFilter, { ignoreCase: true }) ||
            stringContains(workPhone, phoneNumberFilter, { ignoreCase: true }) ||
            stringContains(cellPhone, phoneNumberFilter, { ignoreCase: true })
          ) {
            matchFound = true
            break
          }
        }
      }

      if (matchFound === false) {
        return false
      }

      return true
    })

    return { properties }
  },

  toggleFilters: function() {
    this.filters.show = !this.filters.show
  },

  async reload() {
    this.loading = true
    await this.loadUnits()
    this.loading = false
  }
}
