/*
  example:
    import { getUnitPhotoFiles } from '@/services/Roster/UnitPhoto/procedures/getUnitPhotoFiles';

    const {  exception } = await getUnitPhotoFiles ({
      unitID,
      scaleToWidth
    });
    if (exception) {
      console.error (exception);
      return;
    }
*/
//
import kms from '@/services/kms'
//
import { unitIDIsValid } from '@/utilities/validate/unitID'
//
import { getUnitPhotoList } from '@/services/Roster/UnitPhoto/List'
//
export async function getUnitPhotoFiles({ unitID, scaleToWidth }) {
  if (!unitIDIsValid({ unitID })) {
    console.error(`unitID is not valid`, unitID)
    return
  }

  const { list, exception } = await getUnitPhotoList({
    unitID
  })
  if (exception) {
    console.error(exception)
    return
  }

  var problems = []
  const photos = []

  await Promise.all(
    list.map(entry => {
      return new Promise(async resolve => {
        const { unitPhotoID } = entry

        let photoData
        let isPrimary = false
        try {
          photoData = await kms.get(`/Roster/UnitPhoto/GetUnitPhotoFile`, {
            params: {
              unitPhotoID,
              asBase64: true,
              scaleToWidth: scaleToWidth ? scaleToWidth : null
            }
          })

          isPrimary = false
          const result = await kms.get(`/Roster/UnitPhoto/${unitPhotoID}`)
          if (result && result.isPrimary && result.isPrimary === true) {
            isPrimary = true
          }
        } catch (exception) {
          problems.push({
            exception
          })

          resolve()
        }

        photos.push({
          src: photoData,
          unitPhotoID,
          unitID,
          isPrimary
        })

        resolve()
      })
    })
  )

  return {
    exception: null,
    photos,
    problems
  }
}

///////
