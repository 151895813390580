export const data = function() {
  const unitID = this.$route.params.id || null

  return {
    breakpoint: 'desktop',
    unitImageBase64: '',
    unitID,
    ownerID: 0,
    currentOwner: null,
    loading: false,
    editMode: false,
    uploadEditMode: false,
    editModeData: false,
    iconPadding: '0px',
    file: {
      name: ''
    },
    photos: [],
    primaryPhoto: '',
    selectedTab: 'Overview',
    palette: {
      1: '#ddd',
      2: '#2cabe1'
    },
    tabs: [
      { name: 'overview', label: 'Overview' },
      { name: 'accountsReceivable', label: 'Account Activity' },
      { name: 'architectural', label: 'Architectural' },
      { name: 'collections', label: 'Collections' },
      { name: 'communication', label: 'Conversations' },
      { name: 'compliance', label: 'Compliance' },
      { name: 'ownerContacts', label: 'Contacts' },
      { name: 'emergencyContacts', label: 'Emergency Contacts' },
      { name: 'insurance', label: 'Insurance' },
      { name: 'lateFeeSuspensions', label: 'Late Fee Suspensions' },
      { name: 'notes', label: 'Notes' },
      { name: 'mailingAddress', label: 'Mailing Address' },
      { name: 'priorOwners', label: 'Prior Owners' },
      { name: 'leaseInformation', label: 'Rental Information' },
      { name: 'vehicles', label: 'Vehicles' },
      { name: 'waiverRequests', label: 'Waiver Fee Requests' },
      { name: 'workOrders', label: 'Work Orders' }
    ],
    gallery: {
      loading: true
    },
    styles: {
      mainContainer: {
        minHeight: '1000px'
      }
    },
    transitionName: 'slide-down',
    showUploadModal: false
  }
}
