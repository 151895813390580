import { mapActions } from 'vuex'
import kms from '@/services/kms'
import _get from 'lodash/get'
import _orderBy from 'lodash/orderBy'
import { getUnitPhotoFiles } from '@/services/Roster/UnitPhoto/procedures/getUnitPhotoFiles'
import { overlaysStore } from '@/components/Overlays/store'
import { getOwner } from '@/services/Roster/Owner/getOwner'
import UploadModal from '../components/UploadModal'
import { notifyMessage } from '@/services/notify'

export const methods = {
  ...mapActions('hoa', ['loadUnit']),

  async refresh() {
    this.loading = true

    this.photos = []

    await Promise.all([
      new Promise(async resolve => {
        this.getOwner()

        const { exception, photos, problems } = await getUnitPhotoFiles({
          unitID: this.unitID,
          scaleToWidth: 300
        })
        if (exception) {
          console.error(exception)
          return
        }
        if (Array.isArray(problems) && problems.length >= 1) {
          console.debug(problems)
        }

        this.photos = _orderBy(photos, 'isPrimary', 'desc')

        console.debug('this photos=' + JSON.stringify(this.photos))

        this.gallery.loading = false

        resolve()
      }),
      new Promise(async resolve => {
        await this.loadUnit(this.$route.params.id)
        this.loading = false

        resolve()
      })
    ])

    this.loading = false
  },

  async changeEditMode() {
    this.editMode = !this.editMode
  },

  returnToParent() {
    this.$router.replace('/properties')
  },

  async refreshPrimaryPhoto() {
    console.debug('refreshPrimaryPhoto...')
    const unitID = this.unitID

    if (unitID) {
      try {
        this.primaryPhoto = await kms.get(`/Roster/Unit/RetrieveUnitImage`, {
          params: {
            unitID,
            asBase64: true,
            scaleToWidth: 700
          }
        })
      } catch (e) {
        console.debug(e)
      }
    }
  },

  async openUploadModal() {
    await overlaysStore.dispatch('show', {
      component: UploadModal,
      props: {
        toggle: true,
        unitID: this.unitID,
        uploadComplete: this.uploadComplete
      }
    })
  },

  updatePhotoDisplay({ photo }) {
    this.primaryPhoto = photo.src
  },

  galleryUpdateOccurred() {
    this.refresh()
    this.refreshPrimaryPhoto()
    notifyMessage('The photo was successfully updated.')
  },

  uploadComplete() {
    this.refresh()
    this.refreshPrimaryPhoto()
  },

  redirectToCurrentOwner(primaryOwnerID) {
    console.debug('redirectToCurrentOwner()...' + primaryOwnerID)

    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    this.$router
      .push({
        name: 'unit.overview',
        params: {
          id: _get(this, ['$route', 'params', 'id'], null),
          ownerid: primaryOwnerID,
          previousownerid: primaryOwnerID
        }
      })
      .catch(() => {})

    this.reRender()

    loadingComponent.close()
  },

  reRender() {
    //Necessary for now to force other tabs to re-render
    this.$forceUpdate()
    this.$router.go(0)
  },

  async getOwner() {
    const ownerID = _get(this.$route.params, 'ownerid', 0)
    const primaryOwnerID = _get(this, ['unit', 'primaryOwner', 'ownerID'], null)
    const resolvedOwnerID = ownerID > 0 ? ownerID : primaryOwnerID

    console.debug('Resolved ownerid=' + resolvedOwnerID)
    this.ownerID = resolvedOwnerID

    console.debug('in getOwner()...' + this.ownerID)
    const { owner, exception } = await getOwner({
      ownerID: this.ownerID
    })
    if (exception) {
      console.debug('a problem ocurred retrieving getOwner by ownerID')
      console.debug(exception)
      return
    }

    this.currentOwner = owner
    console.debug('currentOwner=' + JSON.stringify(this.currentOwner))
  }
}
