import debounce from 'lodash/debounce'
import _get from 'lodash/get'
import kms from '@/services/kms'
import { addOwnerContact } from './list/addOwnerContact'
import { resetForm } from './list/resetForm'

import { notifyMessage, notifyProblem } from '@/services/notify'

export const methods = {
  loadAddOwnerContact() {
    this.selectedID = null
    this.action = 'add'

    this.resetForm()
    this.showForm = true
  },

  confirmDelete(id, firstName, lastName) {
    this.$buefy.dialog.confirm({
      title: 'Deleting Contact',
      message: `Are you sure you want to <b>delete</b> this Owner Contact: '${firstName} ${lastName}'?`,
      confirmText: 'Delete Contact',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteRecord(id)
    })
  },

  getAsyncData: debounce(function(name) {
    // String update
    if (this.name !== name) {
      this.name = name
      this.data = []
    }

    // String cleared
    if (!name.length) {
      this.data = []
      return
    }

    this.isFetching = true
    if (this.isDebug == true) console.debug('this.name=' + this.name)

    this.data = this.addressDropDownList.dropDownAddresses.filter(i =>
      i.label.toLowerCase().includes(this.name.toLowerCase())
    )
    this.isFetching = false
  }, 500),

  getMoreAsyncData: debounce(function() {
    this.getAsyncData(this.name)
  }, 250),

  parseFormData() {
    console.debug('parseFormData...' + JSON.stringify(this.formData))

    return {
      ownerContactID: _get(this, ['formData', 'ownerContactID'], null),
      ownerID: _get(this, ['ownerID'], null),
      firstName: _get(this, ['formData', 'firstName'], ''),
      lastName: _get(this, ['formData', 'lastName'], ''),
      emailAddressOne: _get(this, ['formData', 'emailAddressOne'], null),
      emailAddressTwo: _get(this, ['formData', 'emailAddressTwo'], null),
      emailAddressThree: _get(this, ['formData', 'emailAddressThree'], null),
      phone: _get(this, ['formData', 'homePhone'], null),
      workPhone: _get(this, ['formData', 'workPhone'], null),
      cellPhone: _get(this, ['formData', 'cellPhone'], null),
      isPrimaryContact: _get(this, ['formData', 'isPrimaryContact'], null)
    }
  },

  initButtons() {
    this.selectedID = null
    this.formShow = false
    this.action = ''
  },

  loadOwnerContact() {
    this.resetForm()
    this.selectedID = null
    this.formShow = !this.formShow
    this.action = this.action == '' ? 'add' : ''
  },

  loadUpdateOwnerContact(id) {
    this.selectedID = id
    this.formShow = true
    this.action = 'update'
  },

  resetForm,

  updateFormData(row) {
    this.formData.ownerContactID = _get(row, 'ownerContactID', null)
    this.formData.firstName = _get(row, 'firstName', '')
    this.formData.lastName = _get(row, 'lastName', '')
    this.formData.homePhone = _get(row, 'phone', null)
    this.formData.workPhone = _get(row, 'workPhone', null)
    this.formData.cellPhone = _get(row, 'cellPhone', null)
    this.formData.emailAddressOne = _get(row, 'emailAddressOne', null)
    this.formData.emailAddressTwo = _get(row, 'emailAddressTwo', null)
    this.formData.emailAddressThree = _get(row, 'emailAddressThree', null)
    this.formData.isPrimaryContact = _get(row, 'isPrimaryContact', false)
    this.showForm = true
  },

  showFormValidationErrors({ exception }) {
    const fields = _get(exception, ['fields'], null)

    const problems = []
    for (const key in fields) {
      const problem = _get(fields, [key], []).join(' ')
      problems.push(problem)
      this.formErrors[key] = problem
    }

    const problemsMessage = problems.join(' ')
    if (problemsMessage.length === 0) {
      notifyProblem(exception)
    } else {
      notifyProblem(problems.join(' '))
    }
  },

  // API calls
  async getRefreshed() {
    this.loading = true
    if (this.ownerID && this.ownerID != undefined) {
      const params = {
        ownerID: this.ownerID
      }

      if (this.isDebug == true) console.debug('params=' + JSON.stringify(params))

      const { results: returned } = await kms.get(`/Roster/OwnerContact/List`, {
        params
      })

      if (returned) {
        this.ownerContactList = returned
        console.debug('owner contact list results=' + JSON.stringify(this.ownerContactList))
      }

      this.initButtons()
    }

    this.loading = false
  },

  reRender() {
    //Necessary for now to force other tabs to re-render
    this.$forceUpdate()
    this.$router.go(0)
  },

  async deleteRecord(id) {
    if (id <= 0) {
      notifyProblem('Unable to delete this contact.')
      return
    }

    try {
      this.loading = true
      const data = { ownerContactID: id, forceDelete: false }
      const results = await kms.delete(`/Roster/OwnerContact/${id}?forceDelete=false`, data)

      if (this.isDebug == true) console.debug(JSON.stringify(results))

      if (results.recordID >= 0) {
        this.resetForm()
        this.getRefreshed()

        notifyMessage(`The selected contact was successfully deleted.`)
      } else {
        notifyProblem('There was a problem deleting this contact.')
      }
    } catch (e) {
      notifyProblem(e)
    }

    this.loading = false
  },

  async updateOwnerContact() {
    if (!this.formData || !this.ownerID) {
      notifyProblem('Unable to update this contact.')
      return
    }

    try {
      this.loading = true

      const path = `/Roster/OwnerContact`
      const results = await kms.put(path, this.parseFormData())

      if (this.isDebug == true) console.debug(JSON.stringify(results))

      if (results && results.ownerContactID > 0) {
        if (
          results.isPrimaryContact !== undefined &&
          results.isPrimaryContact &&
          results.isPrimaryContact === true
        ) {
          this.reRender()
        } else {
          this.resetForm()
          this.getRefreshed()
        }

        notifyMessage(`The selected contact was successfully updated.`)
        this.showForm = false
      } else {
        notifyProblem('There was a problem updating this contact.')
      }
    } catch (exception) {
      this.showFormValidationErrors({
        exception
      })
    }

    this.loading = false
  },

  addOwnerContact
}
