<template>
  <div
    :style="{
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    }"
  >
    <div
      v-if="address"
      role="region"
      tabindex="0"
      aria-label="property address or addresses"
      class="card-content address-card"
    >
      <h5 tabindex="0" :style="{ fontSize: '22px', paddingBottom: '6px' }">
        {{ address.addressOne || '' }}
      </h5>

      <h6 v-if="address.addressTwo" tabindex="0" :style="{ color: palette[1] }">
        {{ address.addressTwo || '' }}
      </h6>
      <h6 v-if="address.addressThree" tabindex="0" :style="{ color: palette[1] }">
        {{ address.addressThree || '' }}
      </h6>
      <h6 v-if="address.addressFour" tabindex="0" :style="{ color: palette[1] }">
        {{ address.addressFour || '' }}
      </h6>
      <h6 v-if="address.addressFive" tabindex="0" :style="{ color: palette[1] }">
        {{ address.addressFive || '' }}
      </h6>

      <h6 tabindex="0" :style="{ color: palette[1] }">
        {{ address.city || '' }}, {{ address.state || '' }}
        {{ address.postalCode || '' }}
      </h6>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    address: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      palette: {
        1: '#000'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.address-card {
  h5,
  h6 {
    margin: 0;
  }

  h6 {
    font-size: 14px;
    font-weight: normal;
  }
}
</style>
