<template>
  <PageContentLayoutOnly>
    <ListHomeOwnerConversations
      :ownerAuthor="currentOwnerName"
      :authenticatedUser="authUser.name"
      :ownerID="parseInt(ownerID)"
    />
  </PageContentLayoutOnly>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import _get from 'lodash/get'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import ListHomeOwnerConversations from '@/pages/Shared/communications/Global/List'
import { getOwner } from '@/services/Roster/Owner/getOwner'

export default {
  name: 'Communication',
  components: {
    PageContentLayoutOnly,
    ListHomeOwnerConversations
  },

  data: () => ({
    isDebug: true,
    loading: true,
    ownerID: 0,
    currentOwner: null,
    currentOwnerName: '',

    styles: {
      dateSelector: {
        display: 'flex',
        padding: '12px',
        textAlign: 'center',
        verticalAlign: 'middle',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'flex-end'
      },
      filterButton: {
        float: 'right',
        marginTop: '4px'
      }
    }
  }),

  created() {
    if (this.authUser && this.authUser != undefined) {
      this.ownerID = this.$route.params.ownerid || 0
    }
  },

  methods: {
    async reload() {
      this.loading = true
      if (this.unit && this.unit != undefined) {
        this.unitID = this.unit.unitID
      }
      this.getOwner()
      this.loading = false
    },

    async getOwner() {
      const ownerID = _get(this.$route.params, 'ownerid', 0)
      const primaryOwnerID = _get(this, ['unit', 'primaryOwner', 'ownerID'], null)
      const resolvedOwnerID = ownerID > 0 ? ownerID : primaryOwnerID

      console.debug('Resolved ownerid=' + resolvedOwnerID)
      this.ownerID = resolvedOwnerID

      console.debug('in getOwner()...' + this.ownerID)
      const { owner, exception } = await getOwner({
        ownerID: this.ownerID
      })
      if (exception) {
        console.debug('a problem ocurred retrieving getOwner by ownerID')
        console.debug(exception)
        return
      }

      this.currentOwner = owner
      console.debug('currentOwner=' + JSON.stringify(this.currentOwner))

      if (this.currentOwner) {
        this.currentOwnerName = this.currentOwner.name
      }
    }
  },

  computed: {
    ...mapState({
      ...mapGetters('user', ['authUser', 'retrieveUser']),
      unit: state => state.hoa.unit
    }),
    primaryContact() {
      return _get(this, ['unit', 'primaryOwner', 'ownerContacts', 0], null)
    }
  },

  async mounted() {
    await this.reload()
  }
}
</script>

<style></style>
