<template>
  <Modal :toggle.sync="show" :styles="{ modal: { paddingBottom: '22px' } }" uploader-modal>
    <b-field>
      <b-upload v-model="dropFiles" multiple drag-drop expanded>
        <section class="section">
          <div class="content has-text-centered">
            <p>
              <b-icon icon="upload" size="is-large"></b-icon>
            </p>
            <p>Drop photos here or click to select</p>
          </div>
        </section>
      </b-upload>
    </b-field>

    <div class="tags">
      <span v-for="(file, index) in dropFiles" :key="index" class="tag is-primary">
        {{ file.name }}
        <button class="delete is-small" type="button" @click="deleteDropFile(index)"></button>
      </span>
    </div>

    <!-- eslint-disable vue/no-parsing-error -->
    <b-button type="is-primary is-rounded" @click="upload" :style="{ float: 'right' }"
      >Upload Property Photo{{ dropFiles.length <= 1 ? '' : 's' }}</b-button
    >
    <!-- eslint-enable vue/no-parsing-error -->
  </Modal>
</template>

<script>
import _get from 'lodash/get'
//
import Modal from '@/components/Modal'
import kms from '@/services/kms'
import { notifyMessage, notifyProblem } from '@/services/notify'

export default {
  components: {
    Modal
  },
  data() {
    return {
      // file: {},
      dropFiles: [],
      show: this.toggle
    }
  },
  props: {
    toggle: {
      type: Boolean,
      default: false
    },
    unitID: {
      type: [Number, String],
      default: null
    },
    uploadComplete: Function
  },
  methods: {
    deleteDropFile(index) {
      this.dropFiles.splice(index, 1)
    },

    /*
      Steps:
        POST /Roster/UnitPhoto/StoreUnitPhoto
          // the upload step

        POST /Roster/UnitPhoto
          // associates the photo (documentID) with a property (unitID)
    */
    async upload() {
      if (this.dropFiles.length === 0) {
        notifyProblem(`No photos were selected for upload.`)
        return
      }

      const unitID = this.unitID
      if (!['number', 'string'].includes(typeof unitID) || parseInt(unitID) <= 0) {
        notifyProblem(`The upload could not be completed successfully.`)
        console.error('unitID =', unitID)
      }

      const hoaID = this.$store.getters['user/hoaId']
      if (!['number', 'string'].includes(typeof hoaID) || parseInt(hoaID) <= 0) {
        notifyProblem(`The upload could not be completed successfully.`)
        console.error('hoaID =', hoaID)
      }

      const path = `/Roster/UnitPhoto/StoreUnitPhoto?hoaID=${hoaID}`

      const problems = []
      for (let a = 0; a < this.dropFiles.length; a++) {
        const formData = new FormData()
        formData.append('file', this.dropFiles[a])

        const upload = await kms.post(path, formData, {
          'Content-Type': 'multipart/form-data'
        })

        let documentID = 0
        try {
          documentID = parseInt(_get(upload, 'documentId', 0))
          if (documentID < 1) {
            throw new Error()
          }

          await kms.post(`/Roster/UnitPhoto`, {
            documentID,
            unitID
          })

          this.uploadComplete()
        } catch (exception) {
          console.error(exception)
          problems.push(exception)
          continue
        }
      }

      if (problems.length > 0) {
        notifyProblem(`Sorry, ${problems.length} photos could not be uploaded.`)
        return
      } else {
        notifyMessage(`${this.dropFiles.length} photos were uploaded.`)
      }

      this.dropFiles = []
      this.show = false
    }
  },
  watch: {
    toggle(toggle) {
      if (!toggle) {
        this.$emit('update:toggle', false)
      } else if (toggle) {
        this.show = true
      }
    },
    show(show) {
      if (!show) {
        this.$emit('update:toggle', false)
      }
    }
  }
}
</script>
