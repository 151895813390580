/*
  example:
    import { generateOwnerSignupCode } from '@/services/Roster/Owner/GenerateSignupCode';

    const { code, successful, message } = await generateOwnerSignupCode ();
*/
//
import _get from 'lodash/get'
import kms from '@/services/kms'
//
export async function generateOwnerSignupCode() {
  const message = 'An owner signup code could not be generated.'

  try {
    var result = await kms.get(`/Roster/Owner/GenerateSignupCode`)

    var code = _get(result, ['signupCode'], null)

    return {
      code,
      successful: true,
      message
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    code: null,
    successful: false,
    message
  }
}

///////
