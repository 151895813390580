var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"owner-contacts":""}},[_vm._l((_vm.list),function(contact,indexA){return _c('div',{key:indexA,style:(Object.assign({}, {display: 'flex',
      justifyContent: 'space-between'},
      (indexA !== _vm.list.length - 1 ? { marginBottom: '6px' } : {}),
      {padding: '8px 12px'})),attrs:{"owner-contact":""}},[_c('div',{style:({ flexBasis: '20%' })},[_c('div',{style:({ color: _vm.color[8] })},[_vm._v("Name")]),(
          (contact.firstName &&
            typeof contact.firstName === 'string' &&
            contact.firstName.length >= 1) ||
            (contact.lastName &&
              typeof contact.lastName === 'string' &&
              contact.lastName.length >= 1)
        )?_c('div',{style:({ color: _vm.color[8], fontWeight: 'bold' }),attrs:{"contact-name":""}},[_vm._v(" "+_vm._s(contact.firstName)+" "+_vm._s(contact.lastName)+" ")]):_c('div',{style:({ color: _vm.color[8], fontWeight: 'bold' })},[_vm._v(" Not found ")])]),_c('div',{style:({ flexBasis: '40%', padding: '0 12px' })},[_c('div',{style:({ color: _vm.color[8] })},[_vm._v("Emails")]),(contact.emails && contact.emails.length >= 1)?_c('div',_vm._l((contact.emails),function(email,indexB){return _c('div',{key:indexB,style:({ color: _vm.color[8], fontWeight: 'bold' }),attrs:{"contact-email":""}},[_vm._v(" "+_vm._s(email)+" ")])}),0):_c('div',{style:({ color: _vm.color[8], fontWeight: 'bold' }),attrs:{"email-not-found":""}},[_vm._v(" None found ")])]),_c('div',{style:({ flexBasis: '40%' })},[_c('div',{style:({ color: _vm.color[8] })},[_vm._v("Phone Numbers")]),(contact.phoneNumbers && contact.phoneNumbers.length >= 1)?_c('div',_vm._l((contact.phoneNumbers),function(phoneNumber,indexB){return _c('div',{key:indexB,style:(Object.assign({}, (indexB !== contact.phoneNumbers.length - 1 ? { marginBottom: '6px' } : {}),
            {color: _vm.color[8]})),attrs:{"contact-phone-number":""}},[_c('b-tag',{style:({ fontWeight: 'normal' })},[_vm._v(_vm._s(phoneNumber.kind))]),_c('span',{style:({ paddingLeft: '8px', fontWeight: 'bold' })},[_vm._v(_vm._s(phoneNumber.parsed))])],1)}),0):_c('div',{style:({ color: _vm.color[8], fontWeight: 'bold' })},[_vm._v("None found")])])])}),(!Array.isArray(_vm.list) || _vm.list.length === 0)?_c('div',{style:({
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '6px',
      padding: '8px 12px',
      borderRadius: '6px',
      color: _vm.color[8]
    }),attrs:{"contact-details-not-found":""}},[_vm._v(" Contact details were not found. ")]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }