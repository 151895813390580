<template>
  <div>
    <div class="" account-summary>
      <div class="columns" :style="{ justifyContent: 'space-between' }">
        <div :style="{ padding: '10px 20px', width: '220px' }">
          <b-field label="Balance Owed">
            <h5 class="margin-0" account-activity-balance>{{ balance | accounting }}</h5>
          </b-field>
        </div>
        <div :style="{ padding: '10px 20px', width: '350px' }">
          <label class="label">Summary</label>
          <ul>
            <li v-for="charge in chargeTypeBalances" :key="charge.chargeTypeId">
              <label class="h7 margin-0 is-inline-block has-width-100">
                {{ charge.chargeTypeName }}</label
              >

              <label class="h7 margin-0 is-inline-block has-width-100 has-text-right">
                {{ charge.amount | accounting }}
              </label>
            </li>
          </ul>
        </div>
        <div :style="{ padding: '10px 20px', width: '340px', overflow: 'auto' }">
          <DatePicker ref="startDate" ariaLabel="Filter From Date" label="Filter From" />

          <DatePicker ref="endDate" ariaLabel="To Date" label="To" />

          <b-button
            class="go-button"
            size="is-small"
            type="is-info"
            rounded
            @click="filterByDateRange"
            >Go</b-button
          >
        </div>
      </div>
    </div>

    <hr />

    <div class="pb-4">
      <button
        @click.prevent="downloadAccountActivityHistoryReport()"
        class="button is-primary is-rounded"
      >
        View PDF Ledger
      </button>
    </div>
    <div class="list-content">
      <div class="">
        <ModernTable
          :columns="columns"
          :rows="rows"
          :loading="loading"
          condensed
          :focusable="true"
          :selected.sync="selected"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _get from 'lodash/get'
import _ from 'lodash'
import moment from 'moment'

import DatePicker from '@/components/inputs/DatePicker'
import ModernTable from '@/components/tables/Modern/Table'
import { parseDate } from '@/utilities/Date/parse'
import { completeDate } from '@/utilities/Date/complete'
import SimpleButton from '@/components/buttons/Simple'
import { runAccountActivity } from '@/services/Reports/RunReport/AccountActivity/store'
import { base64toBlob, showPdf } from '@/services/blobutility'

const today = moment()
const defaultStart = moment()
  .subtract(1, 'month')
  .startOf('month')

export default {
  components: {
    DatePicker,
    ModernTable
  },

  props: {
    transactions: {
      type: Array,
      default: () => []
    },
    chargeTypeBalances: {
      type: Array,
      default: () => []
    },
    balance: {
      type: [Number, Object],
      default: null
    },
    loading: {
      type: Boolean,
      default: true
    },
    fromDate: {
      type: [String, Date],
      default: () => {
        return defaultStart.toDate()
      }
    },
    toDate: {
      type: [String, Date],
      default: () => {
        return today.toDate()
      }
    }
  },

  data() {
    return {
      startDate: this.fromDate,
      endDate: this.toDate,
      selectedRow: null,
      selected: null,
      columns: [
        {
          field: 'transactionDate',
          date: true,
          sortable: false,

          label: 'Date',
          width: '14%'
        },
        {
          field: 'transactionType',
          label: 'Transaction Type',

          sortable: false
        },
        {
          field: 'amount',
          label: 'Amount',
          numeric: true,

          sortable: false
        },
        {
          field: 'chargeTypeName',
          label: 'Charge Type',

          sortable: false
        },
        {
          field: 'runningBalance',
          label: 'Running Balance',
          numeric: true,

          sortable: false
        },
        {
          field: 'description',
          label: 'Description',
          sortable: false
        },
        {
          field: 'button',
          label: 'Actions',
          width: '5%'
        }
      ],
      rows: []
    }
  },

  computed: {
    orderedTransactions: function() {
      return _.reverse(this.transactions)
    }
  },

  watch: {
    transactions: function() {
      this.determineRows()
    }
  },

  methods: {
    determineRows() {
      const dateFormat = 'LL'

      const transactions = this.orderedTransactions
      if (!Array.isArray(transactions)) {
        return
      }

      this.unitID = _get(this, '$store.state.hoa.unit.unitID', null)
      this.primaryOwnerID = _get(this, '$store.state.hoa.unit.primaryOwnerID', null)

      const rows = transactions.map(entry => {
        const myPath = `/properties/${this.unitID}/${this.primaryOwnerID}/${
          this.primaryOwnerID
        }/waiverRequests?arTransactionID=${_get(entry, 'arTransactionId', 0)}#tab`

        const currencyFormatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD'
        })

        const waiverRequestStatuses = _get(entry, 'waiverRequestStatuses', null)
        let statusLine = ''
        if (waiverRequestStatuses) {
          Array.from(waiverRequestStatuses).forEach(f => {
            if (f) {
              if (statusLine == '') {
                statusLine = f.status
              } else {
                statusLine += ', ' + f.status
              }
            }
          })
        }

        let canWaive = _get(entry, 'canWaive', false)

        var row = {
          transactionDate: {
            date: parseDate(_get(entry, 'transactionDate', ''), dateFormat),
            format: dateFormat
          },
          transactionType: _get(entry, 'transactionType', ''),
          amount: currencyFormatter.format(_get(entry, 'amount', '')),
          chargeTypeName: _get(entry, 'chargeTypeName', ''),
          runningBalance: currencyFormatter.format(_get(entry, 'runningBalance', '')),
          description: _get(entry, 'description', ''),
          button: {
            component: SimpleButton,
            props: {
              to: {
                path: myPath
              },
              text: 'Waive Fee Request',
              visible: canWaive
            }
          }
        }

        Object.setPrototypeOf(row, {
          id: _get(entry, 'accountID', null)
        })

        return row
      })

      this.rows = rows
    },

    async downloadAccountActivityHistoryReport() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })

      const params = {
        ownerID: _get(this, '$store.state.hoa.unit.primaryOwner.ownerID', null),
        startDate: '',
        endDate: '',
        format: 'PDF'
      }

      await runAccountActivity
        .dispatch('runAccountActivityHistoryBase64Report', {
          params
        })
        .then(({ returned }) => {
          loadingComponent.close()

          if (returned && returned != undefined) {
            if (this.isDebug == true) console.debug('base64pdf........................' + returned)

            const contentType = 'application/pdf'
            const blob = base64toBlob(returned, contentType)

            //this.refreshRedirect()

            showPdf(blob)
          }
          return
        })
    },

    filterByDateRange() {
      const from = this.$refs.startDate.getValue()
      const to = this.$refs.endDate.getValue()

      const { fromDate, toDate } = completeDate({
        from,
        to
      })

      this.$emit('filterChange', {
        fromDate,
        toDate
      })
    }
  }
}
</script>
