/*
  example:
    import { getUnit } from '@/services/Roster/Unit/getUnit';

    const { unit, exception } = await getUnit ({
      unitID
    });
    if (exception) {
      console.error (exception);
      return;
    }
*/
//
import kms from '@/services/kms'
//
export async function getUnit({ unitID, kms: kms_ = kms } = {}) {
  var result = {}

  try {
    result = await kms_.get(`/Roster/Unit/${unitID}`, {
      params: {
        unitID
      }
    })
  } catch (exception) {
    console.error(exception)

    return {
      unit: null,
      exception
    }
  }

  return {
    unit: result,
    exception: null
  }
}

///////
