export function data() {
  return {
    hoaID: null,
    ownerID: null,
    unitID: null,
    //
    archReqs: [],
    workOrders: [],
    activeLease: null,
    balanceLabel: 'Balance Owed',
    balanceOwed: '?',
    signUpCode: 'not found',
    showSignUpCode: false,
    //
    loading: true
  }
}
