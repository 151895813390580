<template>
  <div>
    <svg
      style="enable-background:new 0 0 32 32;"
      version="1.1"
      viewBox="0 0 32 32"
      xml:space="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <path
        :style="{
          fill: color,
          stroke: contour,
          strokeWidth: '2',
          'stroke-miterlimit': '10'
        }"
        d="M16.7,2.5l3.1,9.3c0.1,0.3,0.4,0.5,0.7,0.5h9.9c0.7,0,0.9,0.8,0.4,1.2l-8,5.7c-0.2,0.2-0.3,0.5-0.2,0.8l3.1,9.3  c0.2,0.6-0.5,1.1-1.1,0.8l-8-5.7c-0.2-0.2-0.6-0.2-0.8,0l-8,5.7c-0.5,0.4-1.3-0.1-1.1-0.8l3.1-9.3c0.1-0.3,0-0.6-0.2-0.8l-8-5.7  C0.7,13,1,12.2,1.7,12.2h9.9c0.3,0,0.6-0.2,0.7-0.5l3.1-9.3C15.6,1.8,16.4,1.8,16.7,2.5z"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    // fill
    color: {
      type: String,
      default: 'none'
    },

    // stroke
    contour: {
      type: String,
      default: '#000'
    }
  }
}
</script>
