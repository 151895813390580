import debounce from 'lodash/debounce'
import _get from 'lodash/get'
import kms from '@/services/kms'
import { onFormSubmit } from './list/onFormSubmit'
import { resetForm } from './list/resetForm'

import { notifyMessage, notifyProblem } from '@/services/notify'

export const methods = {
  loadAddEmergencyContact() {
    this.selectedID = null
    this.action = 'add'

    this.resetForm()
    this.showForm = true
  },
  confirmDelete(id, name) {
    this.$buefy.dialog.confirm({
      title: 'Deleting Emergency Contact',
      message: `Are you sure you want to <b>delete</b> this Emergency Contact: '${name}'?`,
      confirmText: 'Delete Emergency Contact',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteRecord(id)
    })
  },
  getAsyncData: debounce(function(name) {
    // String update
    if (this.name !== name) {
      this.name = name
      this.data = []
    }

    // String cleared
    if (!name.length) {
      this.data = []
      return
    }

    this.isFetching = true
    if (this.isDebug == true) console.debug('this.name=' + this.name)

    this.data = this.addressDropDownList.dropDownAddresses.filter(i =>
      i.label.toLowerCase().includes(this.name.toLowerCase())
    )
    this.isFetching = false
  }, 500),
  getMoreAsyncData: debounce(function() {
    this.getAsyncData(this.name)
  }, 250),
  parseFormData() {
    return {
      emergencyContactID: _get(this, ['formData', 'emergencyContactID'], null),
      ownerID: _get(this, ['ownerID'], null),

      name: _get(this, ['formData', 'name'], null),

      foreignAddress: _get(this, ['formData', 'foreignAddress'], false),
      addressID: _get(this, ['formData', 'addressID'], null),
      address1: _get(this, ['formData', 'address1'], null),
      address2: _get(this, ['formData', 'address2'], null),
      address3: _get(this, ['formData', 'address3'], null),
      city: _get(this, ['formData', 'city'], null),
      region: _get(this, ['formData', 'region'], null),
      postalCode: _get(this, ['formData', 'postalCode'], null),

      homePhone: _get(this, ['formData', 'homePhone'], null),
      workPhone: _get(this, ['formData', 'workPhone'], null),
      cellPhone: _get(this, ['formData', 'cellPhone'], null),

      homeEmail: _get(this, ['formData', 'homeEmail'], null),
      workEmail: _get(this, ['formData', 'workEmail'], null)
    }
  },
  initButtons() {
    this.selectedID = null
    this.formShow = false
    this.action = ''
  },
  loadEmergencyContact() {
    this.resetForm()
    this.selectedID = null
    this.formShow = !this.formShow
    this.action = this.action == '' ? 'add' : ''
  },
  loadUpdateEmergencyContact(id) {
    this.selectedID = id
    this.formShow = true
    this.action = 'update'
  },
  processForeignAddress() {
    this.formData.ForeignAddress = !this.formData.ForeignAddress
    this.foreignAddress = this.formData.ForeignAddress
    if (this.isDebug == true) console.debug('isForeignAddress...' + this.formData.ForeignAddress)
  },
  resetForm,
  updateFormData(row) {
    this.formData.emergencyContactID = _get(row, 'emergencyContactID', null)
    this.formData.name = _get(row, 'name', null)
    this.formData.homePhone = _get(row, 'homePhone', null)
    this.formData.workPhone = _get(row, 'workPhone', null)
    this.formData.cellPhone = _get(row, 'cellPhone', null)
    this.formData.homeEmail = _get(row, 'homeEmail', null)
    this.formData.workEmail = _get(row, 'workEmail', null)
    this.formData.address1 = _get(row, ['address', 'addressOne'], null)
    this.formData.address2 = _get(row, ['address', 'addressTwo'], null)
    this.formData.address3 = _get(row, ['address', 'addressThree'], null)
    this.formData.city = _get(row, ['address', 'city'], null)
    this.formData.region = _get(row, ['address', 'state'], null)
    this.formData.postalCode = _get(row, ['address', 'postalCode'], null)
    this.formData.foreignAddress = _get(row, ['address', 'foreignAddress'], false)
    this.formData.addressID = _get(row, 'addressID', null)
    this.foreignAddress = this.formData.foreignAddres || false

    console.debug('foreign address=' + this.foreignAddress)

    this.showForm = true
  },

  showFormValidationErrors({ exception }) {
    const fields = _get(exception, ['fields'], null)

    const problems = []
    for (const key in fields) {
      const problem = _get(fields, [key], []).join(' ')
      problems.push(problem)
      this.formErrors[key] = problem
    }

    // this.errorMessages = problems

    const problemsMessage = problems.join(' ')
    if (problemsMessage.length === 0) {
      notifyProblem(exception)
    } else {
      notifyProblem(problems.join(' '))
    }
  },

  // API calls
  async getRefreshed() {
    this.loading = true
    if (this.ownerID && this.ownerID != undefined) {
      const params = {
        ownerID: this.ownerID
      }

      if (this.isDebug == true) console.debug('params=' + JSON.stringify(params))

      const { results: returned } = await kms.get(`/Roster/EmergencyContact/List`, {
        params
      })

      if (returned) {
        this.emergencyContactList = returned
        if (this.isDebug == true && 1 == 2)
          console.debug('results=' + JSON.stringify(this.emergencyContactList))
      }

      this.initButtons()
    }

    this.loading = false
  },
  async deleteRecord(id) {
    if (id <= 0) {
      notifyProblem('Unable to delete this emergency contact.')
      return
    }

    try {
      this.loading = true
      const data = { emergencyContactID: id, forceDelete: false }
      const results = await kms.delete(`/Roster/EmergencyContact/${id}?forceDelete=false`, data)

      if (this.isDebug == true) console.debug(JSON.stringify(results))

      if (results.recordID >= 0) {
        this.resetForm()
        this.getRefreshed()

        notifyMessage(`The selected emergency contact was successfully deleted.`)
      } else {
        notifyProblem('There was a problem deleting this emergency contact.')
      }
    } catch (e) {
      notifyProblem(e)
    }

    this.loading = false
  },
  async updateEmergencyContact() {
    if (!this.formData || !this.ownerID) {
      notifyProblem('Unable to update this emergency contact.')
      return
    }

    try {
      this.loading = true

      const path = `/Roster/EmergencyContact`
      const results = await kms.put(path, this.parseFormData())

      if (this.isDebug == true) console.debug(JSON.stringify(results))

      if (results && results.emergencyContactID > 0) {
        this.resetForm()
        this.getRefreshed()

        notifyMessage(`The selected emergency contact was successfully updated.`)
        this.showForm = false
      } else {
        notifyProblem('There was a problem updating this emergency contact.')
      }
    } catch (exception) {
      this.showFormValidationErrors({
        exception
      })
    }

    this.loading = false
  },
  onFormSubmit
}
