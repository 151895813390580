<template>
  <div
    :style="{
      position: 'relative',
      display: 'flex',

      justifyContent: 'center',
      alignItems: 'center',

      height: size,
      width: '100%'
    }"
  >
    <div
      :style="{
        position: 'relative',

        height: '100%',
        width: size
      }"
      ref="crate"
      primary-photo
    ></div>
    <figure
      v-if="showIcon"
      class="image owner-avatar"
      :style="{
        position: 'absolute',
        top: '-20px',
        left: '50%',
        transform: 'translateX(-50%)',

        boxSizing: 'content-box',
        border: '5px solid #fff',
        height: '50px',
        width: '50px',

        boxShadow: '0 0 4px -2px #222',
        borderRadius: '50%'
      }"
    >
      <img
        src="@/assets/images/default-user.jpg"
        alt="Default Image"
        class="is-rounded avatar-img"
      />
    </figure>
  </div>
</template>

<script>
import { setStyles } from '@/utilities/Element/setStyles'

function PropPhoto({ src }) {
  var figure = document.createElement('figure')
  setStyles(
    {
      position: 'absolute',
      top: 0,
      left: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      height: '100%',
      width: '100%',
      transition: 'opacity .5s',
      opacity: 0
    },
    figure
  )
  figure.className = 'image is-5x3 details-img'

  var img = document.createElement('img')
  setStyles(
    {
      position: 'absolute',
      flexShrink: 0,
      maxWidth: '100%',
      maxHeight: '100%',
      objectFit: 'contain'
    },
    img
  )
  img.src = src

  figure.appendChild(img)

  return figure
}

export default {
  props: {
    showIcon: {
      default: false,
      type: Boolean
    },
    src: String,
    size: {
      default: '100%',
      type: String
    }
  },
  data() {
    return {
      themes: {
        img: {
          position: 'absolute',
          flexShrink: 0,
          maxWidth: '100%',
          maxHeight: '100%',
          objectFit: 'contain',
          transition: 'opacity 2s',
          opacity: 0
        }
      }
    }
  },
  methods: {
    setPhoto({ value }) {
      if (typeof value !== 'string') {
        console.error('PrimaryPhoto src did not receive a string, got', value)
        return
      }

      if (value.length > 1) {
        const crate = this.$refs.crate

        const propPhoto = PropPhoto({
          src: value
        })
        crate.appendChild(propPhoto)

        setTimeout(() => {
          propPhoto.style.opacity = 1
        }, 50)

        const images = crate.children

        for (let a = 0; a < images.length; a++) {
          const image = images[a]

          if (image !== propPhoto) {
            image.style.opacity = 0

            setTimeout(() => {
              try {
                crate.removeChild(image)
              } catch (ex) {
                // the photo may have already been erased
                // e.g.
                //  2 clicked,
                //  3 clicked,
                //  1 removed via 2,
                //  1 attempted removal via 3 but already deleted
                //
                // so error is suppressed
              }
            }, 500)
          }
        }
      }
    }
  },
  mounted() {
    this.setPhoto({
      value: this.src
    })
  },
  watch: {
    src(value) {
      this.setPhoto({ value })
    }
  }
}
</script>
