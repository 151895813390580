var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
    position: 'relative',
    display: 'flex',

    justifyContent: 'center',
    alignItems: 'center',

    height: _vm.size,
    width: '100%'
  })},[_c('div',{ref:"crate",style:({
      position: 'relative',

      height: '100%',
      width: _vm.size
    }),attrs:{"primary-photo":""}}),(_vm.showIcon)?_c('figure',{staticClass:"image owner-avatar",style:({
      position: 'absolute',
      top: '-20px',
      left: '50%',
      transform: 'translateX(-50%)',

      boxSizing: 'content-box',
      border: '5px solid #fff',
      height: '50px',
      width: '50px',

      boxShadow: '0 0 4px -2px #222',
      borderRadius: '50%'
    })},[_c('img',{staticClass:"is-rounded avatar-img",attrs:{"src":require("@/assets/images/default-user.jpg"),"alt":"Default Image"}})]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }