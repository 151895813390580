import _get from 'lodash/get'
import { notifyProblem } from '@/services/notify'
import kms from '@/services/kms'
//
export const methods = {
  async setPhotoAsPrimary(photo) {
    const { documentID, unitPhotoID, unitID } = photo
    var params = {}
    try {
      params.documentID = parseInt(documentID)
      params.unitPhotoID = parseInt(unitPhotoID)
      params.unitID = parseInt(unitID)
      params.isPrimary = true

      if (params.documentID < 1 || params.unitPhotoID < 1 || params.unitID < 1) {
        notifyProblem('Sorry, a problem occurred and the photo could not be set as primary.')
        console.error({ params })
        return
      }
    } catch (exception) {
      notifyProblem('Sorry, a problem occurred and the photo could not be set as primary.')
      console.error(exception)
    }

    try {
      await kms.put(`/Roster/UnitPhoto`, params)
      this.updateOccurred()
    } catch (exception) {
      console.error(exception)
      return
    }
  },
  confirmDeletePhoto(photo) {
    this.$buefy.dialog.confirm({
      title: 'Deleting Photo',
      message: `Are you sure you want to <b>delete</b> this photo? <img src="${photo.src}" style="max-width: 102px; max-height: 102px; margin: 20px auto; border-radius: 4px;" />`,
      confirmText: 'Delete Photo',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deletePhoto(photo)
    })
  },
  async deletePhoto(photo) {
    try {
      const unitPhotoID = parseInt(photo.unitPhotoID)

      if (unitPhotoID < 1) {
        notifyProblem('Sorry, a problem occurred and the photo could not be set as primary.')
        console.error({ unitPhotoID })
        return
      }

      await kms.delete(`/Roster/UnitPhoto/${unitPhotoID}?forceDelete=true`)

      this.updateOccurred()
    } catch (exception) {
      notifyProblem('Apologies, a problem occurred and the photo could not be deleted.')
    }
  },
  getTheme() {
    const theme = _get(this, ['$store', 'getters', 'state', 'theme'], {})

    return {
      star: {
        contour: _get(theme, ['color', '3'], '#000'),
        primary: {
          color: _get(theme, ['color', '1'], 'purple')
        },
        nonPrimary: {
          color: 'none'
        }
      },
      closeColor: _get(theme, ['color', '2'], '#fff')
    }
  }
}
