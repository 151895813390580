import _get from 'lodash/get'
import { mapState } from 'vuex'
import { stringContains } from '@/utilities/String/contains'

export const computed = {
  ...mapState({
    hoaId: state => state.user.selectedHoaId,
    breakpoint: state => state.win.breakpoint
  }),

  /*
    filter based on email and phone number
  */
  filteredEmails() {
    const emailFilter = this.emailFilter

    const { properties: props } = this.getFilteredProperties({
      units: this.units,
      emailFilter: this.emailFilter,
      phoneNumberFilter: this.phoneNumberFilter
    })

    const filteredEmails = []

    function addEmail(text) {
      if (typeof text === 'string' && text.length >= 1) {
        filteredEmails.push({
          value: text
        })
      }
    }

    for (let a = 0; a < props.length; a++) {
      const prop = props[a]
      const ownerContacts = _get(prop, ['primaryOwner', 'ownerContacts'], [])

      if (Array.isArray(ownerContacts)) {
        for (let a = 0; a < ownerContacts.length; a++) {
          const ownerContact = ownerContacts[a]

          const emailOne = _get(ownerContact, ['emailAddressOne'], null)
          const emailTwo = _get(ownerContact, ['emailAddressTwo'], null)
          const emailThree = _get(ownerContact, ['emailAddressThree'], null)

          if (stringContains(emailOne, emailFilter, { ignoreCase: true })) {
            addEmail(emailOne)
          }
          if (stringContains(emailTwo, emailFilter, { ignoreCase: true })) {
            addEmail(emailTwo)
          }
          if (stringContains(emailThree, emailFilter, { ignoreCase: true })) {
            addEmail(emailThree)
          }
        }
      }
    }

    return filteredEmails.sort((a, b) => {
      if (a.value > b.value) return 1
      else if (a.value < b.value) return -1

      return 0
    })
  },

  filteredPhoneNumbers() {
    const phoneNumberFilter = this.phoneNumberFilter

    const { properties: props } = this.getFilteredProperties({
      units: this.units,
      emailFilter: this.emailFilter,
      phoneNumberFilter
    })

    const filteredList = []

    function add(text) {
      if (typeof text === 'string' && text.length >= 1) {
        filteredList.push({
          value: text
        })
      }
    }

    for (let a = 0; a < props.length; a++) {
      const prop = props[a]
      const ownerContacts = _get(prop, ['primaryOwner', 'ownerContacts'], [])

      if (Array.isArray(ownerContacts)) {
        for (let a = 0; a < ownerContacts.length; a++) {
          const ownerContact = ownerContacts[a]

          const phone = _get(ownerContact, ['phone'], null)
          const workPhone = _get(ownerContact, ['workPhone'], null)
          const cellPhone = _get(ownerContact, ['cellPhone'], null)

          if (stringContains(phone, phoneNumberFilter, { ignoreCase: true })) {
            add(phone)
          }
          if (stringContains(workPhone, phoneNumberFilter, { ignoreCase: true })) {
            add(workPhone)
          }
          if (stringContains(cellPhone, phoneNumberFilter, { ignoreCase: true })) {
            add(cellPhone)
          }
        }
      }
    }

    return filteredList.sort((a, b) => {
      if (a.value > b.value) return 1
      else if (a.value < b.value) return -1

      return 0
    })
  }
}
