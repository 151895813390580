var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"property-photo-gallery",style:({
    position: 'relative',

    width: '100%',
    minHeight: '100px',

    paddingRight: '10px',
    paddingLeft: '6px',
    paddingTop: '16px',

    overflowY: 'scroll',
    whiteSpace: 'nowrap'
  }),attrs:{"mobile-gallery":"","role":"region","tabindex":"0","aria-label":"property photo gallery"}},[_vm._l((_vm.photos),function(photo,index){return _c('figure',{key:index,staticClass:"mobile-gallery-figure",style:(Object.assign({}, {position: 'relative',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',

      width: '95px',
      height: '71px'},

      (true ? { marginLeft: '10px' } : {}),

      {overflow: 'hidden',
      borderRadius: '6px',

      cursor: 'pointer'})),attrs:{"tabindex":"0","aria-label":'photo ' + (index + 1)},on:{"click":function($event){return _vm.updatePhotoDisplay({ photo: photo, index: index })}}},[_c('transition',{attrs:{"name":"fade"}},[_c('img',{staticClass:"mobile-gallery-img",style:({
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translateX(-50%) translateY(-50%)',

          flexShrink: '0',
          maxWidth: '100%',
          maxHeight: '100%',
          objectFit: 'contain'
        }),attrs:{"src":photo.src}})]),_c('transition',{attrs:{"name":"fade"}},[(_vm.editMode)?_c('button',{style:({
          position: 'absolute',
          top: '4px',
          left: '4px',
          width: '20px',
          height: '20px',
          outline: 0
        }),on:{"click":function($event){return _vm.confirmDeletePhoto(photo)}}},[_c('TrashCan',{style:({
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%'
          })})],1):_vm._e()]),_c('transition',{attrs:{"name":"fade"}},[(_vm.editMode)?_c('button',{style:({
          position: 'absolute',
          top: '4px',
          right: '4px',
          width: '20px',
          height: '20px',
          outline: 0
        }),on:{"click":function($event){return _vm.setPhotoAsPrimary(photo)}}},[_c('Star',{style:({
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%'
          }),attrs:{"contour":photo.isPrimary ? _vm.theme.star.contour : _vm.theme.star.contour,"color":photo.isPrimary ? _vm.theme.star.primary.color : _vm.theme.star.nonPrimary.color}})],1):_vm._e()])],1)}),_c('b-loading',{style:({}),attrs:{"is-full-page":false},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }