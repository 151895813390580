<template>
  <PageContent :title="$t('propertyDetailsJSON.title')">
    <json-box :value="unit"></json-box>
  </PageContent>
</template>

<script>
import JsonBox from '@/components/JsonBox'
import PageContent from '@/containers/PageContent'

import { mapState } from 'vuex'

export default {
  components: { JsonBox, PageContent },
  computed: {
    ...mapState({
      unit: state => state.hoa.unit
    })
  },
  i18n: {
    messages: {
      en: { propertyDetailsJSON: { title: 'JSON Data' } }
    }
  }
}
</script>
