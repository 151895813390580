import _get from 'lodash/get'

export const watch = {
  hoaId() {
    this.refresh()
  },
  $route: function(to, from) {
    const toIndex = _get(to, ['params', 'index'], 1)
    const fromIndex = _get(from, ['params', 'index'], 1)

    this.transitionName = toIndex < fromIndex ? 'slide-up' : 'slide-down'
  },
  unitImageBase64(value) {
    this.primaryPhoto = value
  },
  unitID() {
    this.refreshPrimaryPhoto()
  },
  uploadEditMode() {
    this.editMode = !this.editMode
    console.debug('uploadEditMode2=' + this.uploadEditMode)
  },
  selectedTab() {
    const tab = this.tabs.find(tab => {
      return tab.label === this.selectedTab
    })
    const tabName = tab.name

    this.$router.push({
      name: 'unit.' + tabName,
      hash: '#tab',
      params: {
        id: this.$route.params.id,
        ownerID: this.ownerID
      }
    })
  }
}
