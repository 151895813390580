<template>
  <span style="white-space:nowrap !important;">
    <button
      class="button is-info is-light is-small is-outline is-rounded"
      @click="loadUpdateEmergencyContact(emergencyContactID)"
    >
      Edit</button
    >&nbsp;
    <button
      class="button is-info is-light is-small is-outline is-rounded"
      @click="confirmDelete(emergencyContactID, name)"
    >
      Delete
    </button>
  </span>
</template>

<script>
export default {
  props: ['confirmDelete', 'emergencyContactID', 'name', 'loadUpdateEmergencyContact']
}
</script>
