/*
	import { isBetween } from '@/utilities/Date/isBetween'

	const result = await isBetween ({
		from: 
		to:

		question:
	})
*/

/*
  checks if "question" is between "from" and "to"
    where "question", "from", & "to" are dates
*/
//
import moment from 'moment'
//
export async function isBetween({ from, to, question }) {
  try {
    const isBefore = moment(question).isBefore(moment(to))
    const isAfter = moment(question).isAfter(moment(from))

    if (isBefore === true && isAfter === true) {
      return true
    } else {
      return false
    }
  } catch (x) {
    console.error(x)
  }

  // indeterminable
  return null
}
