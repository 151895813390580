var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
    position: 'relative',
    width: '100%',
    height: '100%'
  }),attrs:{"gallery":""}},[_c('div',{staticClass:"property-photo-gallery",style:({
      position: 'relative',
      width: '100%',
      height: '80%',
      paddingRight: '10px',
      paddingLeft: '6px',
      overflowY: 'scroll'
    })},[_vm._l((_vm.photos),function(photo,index){return _c('figure',{key:index,style:({
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        width: '100%',
        height: '0',
        paddingBottom: '100%',
        marginBottom: '6px',

        overflow: 'hidden',
        borderRadius: '6px',

        cursor: 'pointer',
        boxShadow: '0 12px 18px 0 rgba(0, 0, 0, 0.05)'
      }),attrs:{"tabindex":"0","aria-label":'photo ' + (index + 1)},on:{"click":function($event){return _vm.updatePhotoDisplay({ photo: photo, index: index })}}},[_c('transition',{attrs:{"name":"fade"}},[_c('img',{style:({
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translateX(-50%) translateY(-50%)',

            flexShrink: '0',
            maxWidth: '100%',
            maxHeight: '100%',
            objectFit: 'contain'
          }),attrs:{"src":photo.src}})]),_c('transition',{attrs:{"name":"fade"}},[(_vm.editMode || _vm.editModeData)?_c('button',{style:({
            position: 'absolute',
            top: '4px',
            left: '4px',
            width: '20px',
            height: '20px',
            outline: 0
          }),on:{"click":function($event){return _vm.confirmDeletePhoto(photo)}}},[_c('TrashCan',{style:({
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%'
            })})],1):_vm._e()]),_c('transition',{attrs:{"name":"fade"}},[(_vm.editMode || _vm.editModeData)?_c('button',{style:({
            position: 'absolute',
            top: '4px',
            right: '4px',
            width: '20px',
            height: '20px',
            outline: 0
          }),on:{"click":function($event){return _vm.setPhotoAsPrimary(photo)}}},[_c('Star',{style:({
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%'
            }),attrs:{"contour":photo.isPrimary ? _vm.theme.star.contour : _vm.theme.star.contour,"color":photo.isPrimary ? _vm.theme.star.primary.color : _vm.theme.star.nonPrimary.color}})],1):_vm._e()])],1)}),_c('b-loading',{style:({}),attrs:{"is-full-page":false},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}})],2),_c('div',{style:({
      display: 'flex',
      height: '20%',
      paddingRight: '10px',
      alignItems: 'center',
      justifyContent: 'center'
    })},[_c('div',[_c('button',{staticClass:"icon-container pr-1",style:({
          justifyContent: 'center',
          alignItems: 'center',
          paddingLeft: _vm.iconPadding
        }),attrs:{"edit-property-photos":"","aria-label":"edit property photos"},on:{"click":_vm.showEditGallery}},[_c('div',{class:_vm.editModeData ? 'icon-x' : 'icon-pencil',style:({
            position: 'relative',
            backgroundSize: 'cover'
          })})]),_c('b-button',{style:({
          height: '67px',
          width: '89px'
        }),attrs:{"uploader-opener":"","type":"is-light"},on:{"click":_vm.openUploadModal}},[_c('div',{style:({
            justifyContent: 'center',
            alignItems: 'center'
          })},[_c('div',{staticClass:"icon-property-photo-upload",style:({
              height: '40px',
              width: '40px',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover'
            })}),_c('div',[_vm._v("Upload")])])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }