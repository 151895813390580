/*
  example:
    import { getUnitPhotoList } from '@/services/Roster/UnitPhoto/List';

    const { list, exception } = await getUnitPhotoList ({
      hoaID
    });
    if (exception) {
      console.error (exception);
      return;
    }
*/
//
import _get from 'lodash/get'
//
import kms from '@/services/kms'
//
export async function getUnitPhotoList({ unitID, kms: kms_ = kms } = {}) {
  try {
    const result = await kms_.get(`/Roster/UnitPhoto/List`, {
      params: {
        unitID
      }
    })

    let list = _get(result, 'results', [])
    if (!Array.isArray(list)) {
      list = []
    }

    return {
      successful: true,
      list
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    list: [],
    successful: false
  }
}

///////
