<template>
  <PageContentLayoutOnly :aria-label="$t('ownerContacts.title')" role="region">
    <div class="is-8">
      <div>
        <b-button
          type="is-primary"
          class="is-rounded"
          @click="loadAddOwnerContact()"
          owner-contact-addition-button
          :style="{ marginBottom: '20px' }"
          >Add Owner Contact</b-button
        >
      </div>

      <ModernTable
        :tableLoading="true"
        :region="region"
        :filters="filters"
        :rows="rows"
        :columns="columns"
        :pagination="{
          perPage: 10
        }"
        condensed
        headerColor="#FFF"
      />
    </div>

    <Modal
      owner-contact-addition-modal
      :toggle.sync="showForm"
      :parentRefresh.sync="parentRefresh"
      :styles="{
        modal: {
          height: '80vh',
          overflowY: 'auto'
        }
      }"
    >
      <template v-slot:header>{{
        `${action && action === 'add' ? 'Create' : 'Update'} Owner Contact`
      }}</template>
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <transition name="fade" mode="out-in" appear>
          <form @submit.prevent="handleSubmit(addOwnerContact())" class="form">
            <fieldset>
              <div class="column is-12">
                <valid-input
                  type="hidden"
                  vid="ownerContactID"
                  v-model="formData.ownerContactID"
                  :style="{ visibility: 'hidden' }"
                />
                <label class="label"
                  >First Name<Info
                    title="Name"
                    information="A green check means that the entry is valid for that field. A red colored field will prevent the form from being submitted."
                /></label>
                <valid-input
                  name-input
                  v-model="formData.firstName"
                  :message="formErrors.firstName"
                  rules="max:100|required"
                  style="width:50px !important;"
                  name="First Name"
                  maxlength="100"
                  type="text"
                  vid="FirstName"
                  placeholder="First Name"
                  spellcheck="true"
                  aria-label="First Name"
                  class="is-small"
                />
              </div>
              <div class="column is-12">
                <valid-input
                  name-input
                  v-model="formData.lastName"
                  :message="formErrors.lastName"
                  rules="max:100|required"
                  label="Last Name"
                  style="width:50px !important;"
                  name="Last Name"
                  maxlength="100"
                  type="text"
                  vid="Last Name"
                  placeholder="Last Name"
                  spellcheck="true"
                  aria-label="Last Name"
                  class="is-small"
                />
              </div>
              <div class="column is-12">
                <valid-input
                  v-model="formData.emailAddressOne"
                  :message="formErrors.emailAddressOne"
                  rules="max:255"
                  style="width:50px !important;"
                  name="Email Address One"
                  label="Email Address One"
                  maxlength="255"
                  vid="EmailAddressOne"
                  placeholder="Email Address One"
                  spellcheck="true"
                  aria-label="Email Address One"
                  class="is-small"
                />
              </div>
              <div class="column is-12">
                <valid-input
                  v-model="formData.emailAddressTwo"
                  :message="formErrors.emailAddressTwo"
                  rules="max:255"
                  style="width:50px !important;"
                  name="Email Address Two"
                  label="Email Address Two"
                  maxlength="255"
                  vid="EmailAddressTwo"
                  placeholder="Email Address Two"
                  spellcheck="true"
                  aria-label="Email Address Two"
                  class="is-small"
                />
              </div>
              <div class="column is-12">
                <valid-input
                  v-model="formData.emailAddressThree"
                  :message="formErrors.emailAddressThree"
                  rules="max:255"
                  style="width:50px !important;"
                  name="Email Address Three"
                  label="Email Address Three"
                  maxlength="255"
                  vid="EmailAddressThree"
                  placeholder="Email Address Three"
                  spellcheck="true"
                  aria-label="Email Address Three"
                  class="is-small"
                />
              </div>
              <div class="column is-12">
                <valid-input
                  v-model="formData.homePhone"
                  :message="formErrors.homePhone"
                  style="width:50px !important;"
                  name="HomePhone"
                  label="Home Phone"
                  maxlength="14"
                  type="phone"
                  vid="HomePhone"
                  placeholder="Home Phone (###) ###-####"
                  spellcheck="true"
                  aria-label="Home Phone"
                  rules="max:14"
                  class="is-small"
                  onkeypress="return (event.charCode >= 8 || event.charCode == 46) && event.charCode <= 57"
                />
              </div>
              <div class="column is-12">
                <valid-input
                  v-model="formData.cellPhone"
                  :message="formErrors.cellPhone"
                  style="width:50px !important;"
                  name="CellPhone"
                  label="Cell Phone"
                  maxlength="14"
                  type="phone"
                  vid="CellPhone"
                  placeholder="Cell Phone (###) ###-####"
                  spellcheck="true"
                  aria-label="CellPhone"
                  rules="max:14"
                  class="is-small"
                  onkeypress="return (event.charCode >= 8 || event.charCode == 46) && event.charCode <= 57"
                />
              </div>
              <div class="column is-12">
                <valid-input
                  v-model="formData.workPhone"
                  :message="formErrors.workPhone"
                  style="width:50px !important;"
                  name="WorkPhone"
                  label="Work Phone"
                  maxlength="14"
                  type="phone"
                  vid="WorkPhone"
                  placeholder="Work Phone (###) ###-####"
                  spellcheck="true"
                  aria-label="WorkPhone"
                  rules="max:14"
                  class="is-small"
                  onkeypress="return (event.charCode >= 8 || event.charCode == 46) && event.charCode <= 57"
                />
              </div>
              <div>
                <b-checkbox v-model="formData.isPrimaryContact">
                  Primary Contact
                </b-checkbox>
              </div>
              <div class="column is-12" :style="{ textAlign: 'right' }">
                <button
                  modal-add-button
                  v-if="action && action == 'add'"
                  type="submit"
                  :disabled="loading"
                  class="button is-primary is-rounded"
                >
                  Add
                </button>
                <button
                  modal-update-button
                  v-if="action && action == 'update'"
                  @click.prevent="updateOwnerContact(selectedOwnerContact, selectedID)"
                  class="button is-primary is-rounded"
                >
                  Update
                </button>
              </div>
            </fieldset>

            <fieldset>
              <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                <div class="field">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </fieldset>
          </form>
        </transition>
      </ValidationObserver>
    </Modal>
  </PageContentLayoutOnly>
</template>

<script>
import { mapState } from 'vuex'
import Info from '@/components/icons/Info'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import kms from '@/services/kms'

// components
import Modal from '@/components/Modal'
import ModernTable from '@/components/tables/Modern/Table'
import ValidInput from '@/components/inputs/ValidInput'

import _get from 'lodash/get'
import { methods } from './keys/methods'
import { data } from './keys/data'
import { watch } from './keys/watch'

export const defaultDateFormat = 'MM/dd/yyyy'

export default {
  components: {
    Modal,
    ModernTable,
    PageContentLayoutOnly,
    ValidInput,
    ValidationObserver,
    ValidationProvider,
    Info
  },

  props: {
    // for the purpose of testing
    kms: {
      type: Object,
      default: () => {
        return kms
      }
    }
  },

  data,
  methods,

  created() {
    this.getRefreshed()
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    })
  },

  mounted() {
    this.ownerID = _get(this, '$store.state.hoa.unit.primaryOwner.ownerID', null)
    this.getRefreshed()

    this.resetForm()
  },

  watch,

  i18n: {
    messages: {
      en: { ownerContacts: { title: 'Contacts' } }
    }
  }
}
</script>

<style lang="scss">
.my-grid-class {
  height: 400px;
}

tbody {
  min-height: 100px !important;
  overflow: auto !important;
}
</style>
