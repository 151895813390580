<template>
  <PageContentLayoutOnly role="region">
    <div class="notification headerColor box is-4" v-if="hoaList.hoas && hoaList.hoas != undefined">
      <div style="padding-bottom:10px;" v-for="(c, index) in hoaList.hoas" :key="index">
        <div class="columns">
          <div class="column is-one-third" v-if="c.name">
            <li>{{ c.name }}</li>
            <b-button
              v-show="false"
              class="pb-5"
              type="is-text"
              @click.prevent="leaveHoa(c.hoaID, c.name)"
              ><span class="href-overflow-wrap">Leave</span></b-button
            >
          </div>
        </div>
      </div>
    </div>
  </PageContentLayoutOnly>
</template>

<script>
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import { mapState, mapGetters } from 'vuex'

import { data } from './keys/data'
import { methods } from './keys/methods'

export default {
  components: {
    PageContentLayoutOnly
  },

  data,

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser']),
      ...mapGetters('user', ['authUser', 'retrieveUser'])
    })
  },

  mounted() {
    this.loading = true

    this.reload()

    this.currentAssociation = this.hoaId

    this.loading = false
  },

  watch: {
    hoaId() {
      this.reload()
    },
    toggle() {
      if (this.toggle == false) {
        this.reload()
        this.toggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
      }
    },
    currentAssociation(hoaId) {
      // pass selected HOA ID to Vuex when user changes it here
      this.selectHoaId(hoaId)
    }
  },

  methods,

  i18n: {
    messages: {
      en: { hoaAccounts: { title: 'Hoa Accounts' } }
    }
  }
}
</script>

<style lang="scss">
textarea {
  min-width: 600px !important;
}

button {
  padding: 5px;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 60px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > div {
    width: 940px;
  }

  .app-properties {
    > div {
      margin: 0 8px 8px 0;
      font-style: normal !important;

      > label {
        display: block;
      }
      > span {
        font-style: normal !important;
      }
      .vue-quick-edit {
        display: inline-block;
        margin-left: 8px;
        font-style: normal !important;
      }
    }
  }
}
</style>
