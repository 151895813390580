<template>
  <div
    gallery
    :style="{
      position: 'relative',
      width: '100%',
      height: '100%'
    }"
  >
    <div
      :style="{
        position: 'relative',
        width: '100%',
        height: '80%',
        paddingRight: '10px',
        paddingLeft: '6px',
        overflowY: 'scroll'
      }"
      class="property-photo-gallery"
    >
      <figure
        v-for="(photo, index) in photos"
        :key="index"
        tabindex="0"
        :aria-label="'photo ' + (index + 1)"
        :style="{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',

          width: '100%',
          height: '0',
          paddingBottom: '100%',
          marginBottom: '6px',

          overflow: 'hidden',
          borderRadius: '6px',

          cursor: 'pointer',
          boxShadow: '0 12px 18px 0 rgba(0, 0, 0, 0.05)'
        }"
        @click="updatePhotoDisplay({ photo, index })"
      >
        <transition name="fade">
          <img
            :src="photo.src"
            :style="{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translateX(-50%) translateY(-50%)',

              flexShrink: '0',
              maxWidth: '100%',
              maxHeight: '100%',
              objectFit: 'contain'
            }"
          />
        </transition>

        <transition name="fade">
          <button
            v-if="editMode || editModeData"
            :style="{
              position: 'absolute',
              top: '4px',
              left: '4px',
              width: '20px',
              height: '20px',
              outline: 0
            }"
            @click="confirmDeletePhoto(photo)"
          >
            <TrashCan
              :style="{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%'
              }"
            />
          </button>
        </transition>

        <transition name="fade">
          <button
            v-if="editMode || editModeData"
            :style="{
              position: 'absolute',
              top: '4px',
              right: '4px',
              width: '20px',
              height: '20px',
              outline: 0
            }"
            @click="setPhotoAsPrimary(photo)"
          >
            <Star
              :style="{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%'
              }"
              :contour="photo.isPrimary ? theme.star.contour : theme.star.contour"
              :color="photo.isPrimary ? theme.star.primary.color : theme.star.nonPrimary.color"
            />
          </button>
        </transition>
      </figure>

      <b-loading :style="{}" :is-full-page="false" v-model="loading"></b-loading>
    </div>

    <div
      :style="{
        display: 'flex',
        height: '20%',
        paddingRight: '10px',
        alignItems: 'center',
        justifyContent: 'center'
      }"
    >
      <!-- upload button -->
      <div>
        <button
          edit-property-photos
          aria-label="edit property photos"
          class="icon-container pr-1"
          :style="{
            justifyContent: 'center',
            alignItems: 'center',
            paddingLeft: iconPadding
          }"
          @click="showEditGallery"
        >
          <div
            :class="editModeData ? 'icon-x' : 'icon-pencil'"
            :style="{
              position: 'relative',
              backgroundSize: 'cover'
            }"
          ></div>
        </button>
        <b-button
          uploader-opener
          type="is-light"
          :style="{
            height: '67px',
            width: '89px'
          }"
          @click="openUploadModal"
        >
          <div
            :style="{
              justifyContent: 'center',
              alignItems: 'center'
            }"
          >
            <div
              class="icon-property-photo-upload"
              :style="{
                height: '40px',
                width: '40px',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'
              }"
            ></div>
            <div>Upload</div>
          </div>
        </b-button>
      </div>
      <!-- upload button -->
    </div>
  </div>
</template>

<script>
import Star from '@/components/icons/Star'
import TrashCan from '@/components/icons/Trash-Can'

import { methods } from './keys/methods'

export default {
  components: {
    Star,
    TrashCan
  },
  props: {
    perspective: String,
    updatePhotoDisplay: Function,
    openUploadModal: Function,
    unitID: {
      type: [Number, String],
      default: null
    },
    editMode: Boolean,
    updateOccurred: Function,
    photos: {
      type: Array,
      default() {
        return []
      }
    },
    loading: Boolean
  },
  data() {
    return {
      theme: this.getTheme(),
      uploadEditMode: false,
      editModeData: false,
      iconPadding: 0
    }
  },
  methods
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/_variables';

.icon-container {
  top: 10px;
  right: 10px;

  height: 40px;
  width: 40px;

  .icon-pencil {
    height: 100%;
    width: 100%;
  }

  .icon-x {
    height: 50%;
    width: 50%;
    padding-left: 20px !important;
  }

  &:focus {
    border-radius: 6px;
    box-shadow: $tabable-focus;
  }
}

.owner-avatar {
  margin: 1em auto 2em;
}

.avatar-img {
  border: 1px solid #e7e8f2;
  padding: 0.25em;
}

.card {
  box-shadow: 0 12px 18px 0 rgba(0, 0, 0, 0.05) !important;

  .card {
    border: 0 !important;
  }
}

div.property-photo-gallery {
  &::-webkit-scrollbar {
    width: 6px;
  }

  // &::-webkit-scrollbar-track {
  //   // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  //   // border-radius: 10px;
  // }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    background: #dcdcdc;
  }

  // gecko
  scrollbar-color: #dcdcdc #fff;
  scrollbar-width: thin;
}
</style>
