import Button from '@/components/buttons/Button'

import _get from 'lodash/get'
import { mapActions } from 'vuex'

export const methods = {
  ...mapActions('alertSubscription', ['getSubscriptionNotificationList']),

  toggleFilters: function() {
    this.filters.show = !this.filters.show
  },

  confirmDeleteAlertSubscription(alertSubscriptionID, alertType) {
    this.$buefy.dialog.confirm({
      title: 'Deleting G/L Notification Subscription',
      message: `Are you sure you want to <b>delete</b> the G/L Notification Subscription for "${alertType}"?`,
      confirmText: 'Delete G/L Notification Subscription',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteAlertSubscription(alertSubscriptionID)
    })
  },

  determineSubscriptionAlerts() {
    if (this.isDebug == true) console.debug('determineSubscriptionAlerts()...')

    if (this.subscriptionNotificationList) {
      this.subscriptionAlerts = this.subscriptionNotificationList.map(i => {
        return {
          alertType: _get(i, 'notificationType.name', ''),
          alertDeliveryType: _get(i, 'notificationDeliveryNameCsv', ''),
          edit: {
            component: Button,
            props: {
              onClick: () => {
                this.processAlertSubscriptionUpdate(i)
              },
              text: 'Edit'
            }
          }
        }
      })
    } else {
      console.log('else determineSubscriptionAlerts...')
    }
  },

  async reload() {
    this.loading = true

    const payload = {
      recipientType: 'management'
    }

    try {
      this.$store
        .dispatch('alertSubscription/getSubscriptionNotificationList', {
          payload: payload
        })
        .then(() => {
          this.determineSubscriptionAlerts()
        })
    } catch (e) {
      console.debug('error in method for retrieving notification subscription:' + e)
    }

    this.loading = false
  },

  processAlertSubscriptionUpdate(alertSubscription) {
    this.toggle = true
    this.selectedSubscriptionAlert = alertSubscription
  }
}
