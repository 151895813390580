export const resetForm = async function() {
  this.loading = true

  const DEFAULT_FORM_VALUES = this.DEFAULT_FORM_VALUES
  this.formData = { ...DEFAULT_FORM_VALUES }

  const DEFAULT_FORM_ERRORS = this.DEFAULT_FORM_ERRORS
  this.formErrors = { ...DEFAULT_FORM_ERRORS }

  if (this.$refs.form) {
    this.$refs.form.reset()
  }

  this.selectedAddressID = null
  this.addressFilter = null
  this.foreignAddress = false

  this.loading = false
}
