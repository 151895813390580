<template>
  <div owner-contacts>
    <div
      owner-contact
      v-for="(contact, indexA) in list"
      :key="indexA"
      :style="{
        display: 'flex',
        justifyContent: 'space-between',
        ...(indexA !== list.length - 1 ? { marginBottom: '6px' } : {}),
        padding: '8px 12px'
      }"
    >
      <div :style="{ flexBasis: '20%' }">
        <div :style="{ color: color[8] }">Name</div>
        <div
          contact-name
          :style="{ color: color[8], fontWeight: 'bold' }"
          v-if="
            (contact.firstName &&
              typeof contact.firstName === 'string' &&
              contact.firstName.length >= 1) ||
              (contact.lastName &&
                typeof contact.lastName === 'string' &&
                contact.lastName.length >= 1)
          "
        >
          {{ contact.firstName }} {{ contact.lastName }}
        </div>
        <div v-else :style="{ color: color[8], fontWeight: 'bold' }">
          Not found
        </div>
      </div>

      <div :style="{ flexBasis: '40%', padding: '0 12px' }">
        <div :style="{ color: color[8] }">Emails</div>

        <div v-if="contact.emails && contact.emails.length >= 1">
          <div
            contact-email
            v-for="(email, indexB) in contact.emails"
            :key="indexB"
            :style="{ color: color[8], fontWeight: 'bold' }"
          >
            {{ email }}
          </div>
        </div>
        <div v-else email-not-found :style="{ color: color[8], fontWeight: 'bold' }">
          None found
        </div>
      </div>

      <div :style="{ flexBasis: '40%' }">
        <div :style="{ color: color[8] }">Phone Numbers</div>
        <div v-if="contact.phoneNumbers && contact.phoneNumbers.length >= 1">
          <div
            contact-phone-number
            v-for="(phoneNumber, indexB) in contact.phoneNumbers"
            :key="indexB"
            :style="{
              ...(indexB !== contact.phoneNumbers.length - 1 ? { marginBottom: '6px' } : {}),
              color: color[8]
            }"
          >
            <b-tag :style="{ fontWeight: 'normal' }">{{ phoneNumber.kind }}</b-tag>
            <span :style="{ paddingLeft: '8px', fontWeight: 'bold' }">{{
              phoneNumber.parsed
            }}</span>
          </div>
        </div>
        <div v-else :style="{ color: color[8], fontWeight: 'bold' }">None found</div>
      </div>
    </div>

    <div
      contact-details-not-found
      v-if="!Array.isArray(list) || list.length === 0"
      :style="{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '6px',
        padding: '8px 12px',
        borderRadius: '6px',
        color: color[8]
      }"
    >
      Contact details were not found.
    </div>
  </div>
</template>

<script>
import _get from 'lodash/get'

/*
  <OwnerContacts :contacts="contacts" />
*/
export default {
  props: {
    contacts: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    const theme = _get(this, ['$store', 'state', 'theme'], {})

    return {
      color: theme.color,
      list: this.contacts
    }
  },
  watch: {
    contacts() {
      this.list = this.contacts
    }
  }
}
</script>
