/*
  import { parseE164 } from '@/utilities/phoneNumber/parseE164'

  const parsed = parseE164 ('+18005550000');
  // -> 1 (800) 555-0000
*/

// import parsePhoneNumber from 'libphonenumber-js'

export const parseE164 = function(e164) {
  if (typeof e164 !== 'string') {
    return ''
  }

  if (e164[0] === '+') {
    if (e164[1] === '1') {
      if (e164.length === 12) {
        // for example: +1800555000 -> 1 (800) 555-0000

        return [
          `1 `,
          `(`,
          e164.substring(2, 5),
          `)`,
          ` `,
          e164.substring(5, 8),
          `-`,
          e164.substring(8, 12)
        ].join('')
      }
    }
  }

  if (e164[0] === '1' && e164.length === 11) {
    // for example: 1800555000 -> 1 (800) 555-0000

    return [
      `1 `,
      `(`,
      e164.substring(1, 4),
      `)`,
      ` `,
      e164.substring(4, 7),
      `-`,
      e164.substring(7, 11)
    ].join('')
  }

  return e164
}
