<template>
  <div class="details-section" role="main" aria-label="Overview" :style="{ position: 'relative' }">
    <div class="columns is-multiline">
      <div class="column is-4">
        <label class="h6">Open Architectural Requests</label>

        <router-link
          class="button"
          :style="{
            width: '190px',
            height: 'auto',
            display: 'block',
            wordBreak: 'normal',
            whiteSpace: 'normal'
          }"
          :to="{ name: 'unit.architectural', params: { id: unitID } }"
          >{{ archReqs.length }}</router-link
        >
      </div>

      <div class="column is-4">
        <label class="h6">Open Work Orders</label>

        <router-link
          class="button"
          :style="{
            width: '190px',
            height: 'auto',
            display: 'block',
            wordBreak: 'normal',
            whiteSpace: 'normal'
          }"
          :to="{ name: 'unit.workOrders', params: { id: unitID } }"
          >{{ workOrders.length }}</router-link
        >
      </div>

      <div class="column is-4">
        <label class="h6">Currently Tenant Occupied</label>

        <div
          :style="{
            display: 'flex',
            alignItems: 'center'
          }"
        >
          <h5 :style="{ display: 'inline-block', margin: 0, paddingRight: '10px' }">
            <span v-if="activeLease === true">Yes</span>
            <span v-else-if="activeLease === false">No</span>
            <span v-else>?</span>
          </h5>

          <router-link
            class="button"
            :style="{
              display: 'inline-block',
              width: '190px',
              height: 'auto',
              wordBreak: 'normal',
              whiteSpace: 'normal'
            }"
            :to="{ name: 'unit.leaseInformation', params: { id: unitID } }"
          >
            <span>Show Leases</span>
          </router-link>
        </div>
      </div>

      <div class="column is-4">
        <label class="h6">{{ balanceLabel }}</label>

        <router-link
          class="button"
          :style="{
            width: '190px',
            height: 'auto',
            display: 'block',
            wordBreak: 'normal',
            whiteSpace: 'normal'
          }"
          :to="{ name: 'unit.accountsReceivable', params: { id: unitID } }"
        >
          <span v-if="typeof balanceOwed === 'string'">{{ balanceOwed }}</span>
          <span v-else-if="typeof balanceOwed === 'number'">{{ balanceOwed }}</span>
          <span v-else>?</span>
        </router-link>
      </div>

      <!-- <div class="column is-4">
        <label class="h6">Automatic Payments</label>
        <h5>Off</h5>
      </div> -->

      <div class="column is-4">
        <label class="h6">Invite Code</label>
        <div>
          <h5 :style="{ display: 'inline-block' }">
            {{ showSignUpCode ? signUpCode : '*********' }}
          </h5>
          <button
            :style="{ display: 'inline-block', margin: '0 0 0 8px', borderRadius: '6px' }"
            class="subheading button is-primary is-small is-outlined"
            @click="revealSignUpCode"
          >
            {{ showSignUpCode ? 'Hide' : 'Show' }}
          </button>
        </div>
      </div>

      <!-- <div class="column is-4">
        <label class="h6">Web Account Passcode</label>
        <h5>******** <span class="small">View</span></h5>
        <button class="subheading button is-primary is-small is-outlined">Reset</button>
      </div> -->

      <!-- <div class="column is-4">
        <label class="h6">Move Out Date</label>
        <h5>None Declared</h5>
      </div> -->

      <!-- <div class="column is-4">
        <label class="h6">Pending Escrows</label>
        <h5>None Declared</h5>
      </div> -->
    </div>
    <b-loading :is-full-page="false" v-model="loading"></b-loading>
  </div>
</template>
<script>
import { mapState } from 'vuex'

import { data } from './keys/data'
import { methods } from './keys/methods'
import { mounted } from './keys/mounted'
import { watch } from './keys/watch'

export default {
  computed: {
    ...mapState({
      unit: state => state.hoa.unit,
      user: state => state.user,
      hoaId: state => state.user.selectedHoaId
    })
  },

  data,
  methods,
  mounted,
  watch
}
</script>
