/*
  import { parseOwnerContacts } from '@/services/Roster/Owner/parseOwnerContacts'
*/

import _get from 'lodash/get'
import { parseE164 } from '@/utilities/phoneNumber/parseE164'

export function parseOwnerContacts({ contacts }) {
  return contacts.map(contact => {
    const emails = []

    const emailAddressOne = _get(contact, 'emailAddressOne', null)
    if (typeof emailAddressOne === 'string' && emailAddressOne.length >= 1) {
      emails.push(emailAddressOne)
    }

    const emailAddressTwo = _get(contact, 'emailAddressTwo', null)
    if (typeof emailAddressTwo === 'string' && emailAddressTwo.length >= 1) {
      emails.push(emailAddressTwo)
    }

    const emailAddressThree = _get(contact, 'emailAddressThree', null)
    if (typeof emailAddressThree === 'string' && emailAddressThree.length >= 1) {
      emails.push(emailAddressThree)
    }

    const phoneNumbers = []

    const phone = _get(contact, 'phone', null)
    if (typeof phone === 'string' && phone.length >= 1) {
      phoneNumbers.push({
        kind: 'home phone',
        parsed: parseE164(phone)
      })
    }

    const workPhone = _get(contact, 'workPhone', null)
    if (typeof workPhone === 'string' && workPhone.length >= 1) {
      phoneNumbers.push({
        kind: 'work phone',
        parsed: parseE164(workPhone)
      })
    }

    const cellPhone = _get(contact, 'cellPhone', null)
    if (typeof cellPhone === 'string' && cellPhone.length >= 1) {
      phoneNumbers.push({
        kind: 'cell phone',
        parsed: parseE164(cellPhone)
      })
    }

    return {
      firstName: _get(contact, 'firstName', null),
      lastName: _get(contact, 'lastName', null),
      //
      emails,
      phoneNumbers
    }
  })
}
