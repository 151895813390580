import { mapActions } from 'vuex'
import { ToastProgrammatic as Toast } from 'buefy'
import kms from '@/services/kms'

function notifyError(e) {
  Toast.open({
    duration: 7000,
    message: e.message || e,
    position: 'is-bottom',
    type: 'is-danger'
  })
}

function notifyMessage(e) {
  Toast.open({
    duration: 7000,
    message: e.message || e,
    position: 'is-bottom',
    type: 'is-success'
  })
}

export const methods = {
  ...mapActions('user', ['selectHoaId', 'userRetrieve']),

  async reload() {
    this.loading = true

    this.refreshHoaList()

    this.loading = false
  },

  async refreshHoaList() {
    try {
      const refreshedHoaList = await kms.get(
        '/Hoa/List?showDeletedAccountManagerAssociations=false'
      )

      if (this.isDebug == true) console.debug('refreshedUser=' + JSON.stringify(refreshedHoaList))

      if (refreshedHoaList && refreshedHoaList != undefined) {
        this.hoaList = refreshedHoaList
        if (this.isDebug == true) console.debug('this.hoaList=' + JSON.stringify(this.hoaList))
      } else {
        if (this.isDebug == true) console.debug('else this.hoaList')
      }
    } catch (e) {
      notifyError(e)
    }
  },

  async leaveHoa(leaveHoaID, hoaName) {
    if (!this.authUser || this.authUser == undefined || !leaveHoaID || leaveHoaID == undefined) {
      notifyError('There was a problem leaving this Hoa.')
      return
    }

    const leavePayload = {
      accountManagingUserID: this.authUser.accountManagingUserID,
      accountID: this.authUser.accountID,
      hoaID: leaveHoaID
    }

    try {
      const path = `/User/LeaveHoa?accountManagingUserID=${leavePayload.accountManagingUserID}&accountID=${leavePayload.accountID}&hoaID=${leavePayload.hoaID}`
      const results = await kms.post(path, leavePayload)

      if (this.isDebug == true) console.debug(JSON.stringify(results))

      if (results && results != undefined) {
        if (results == true) {
          notifyMessage(`Successfully left ${hoaName}`)
          this.reload()
        } else {
          notifyError(`There was a problem leaving this Hoa.`)
        }
      } else {
        notifyError('There was a problem leaing this Hoa.')
      }
      this.loading = false
    } catch (e) {
      //If this is a validation error, get the details for each field
      notifyError(e)
      this.loading = false
    }
  },

  setChangePasswordModal() {
    this.toggle = true
  }
}
