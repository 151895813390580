import _get from 'lodash/get'
import { getAReqsList } from '@/services/Architectural/ArchitecturalSubmission/List'
import { listLeases } from '@/services/Lease/Lease/List'
import { getReceivableBalance } from '@/services/AccountsReceivable/LedgerQueries/GetOverallBalance'
import { generateOwnerSignupCode } from '@/services/Roster/Owner/GenerateSignupCode'

//
import { notifyProblem } from '@/services/notify'
import { workOrderList } from '@/services/WorkOrders/store'
//
import { isBetween } from '@/utilities/Date/isBetween'
//
import { getUnit } from '@/services/Roster/Unit/getUnit'

export const methods = {
  async refresh() {
    console.log('refresh')

    this.hoaID = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null)
    if (!['number', 'string'].includes(typeof this.hoaID)) {
      console.error('hoaID could not be determined')
    }

    this.ownerID = this.resolveOwnerID()
    if (!['number', 'string'].includes(typeof this.ownerID)) {
      console.error('ownerID could not be determined')
    }

    this.unitID = _get(this, ['unit', 'unitID'], null)
    if (!['number', 'string'].includes(typeof this.ownerID)) {
      console.error('unitID could not be determined')
    }

    await this.getArchRequests()
    await this.getLeases()
    await this.getBalanceOwed()
    await this.getUnitDetails({
      unitID: this.unitID
    })

    //Re-render only if previousownerid
    const previousOwnerID = _get(this.$route.params, 'previousownerid', 0)
    if (this.ownerID !== previousOwnerID) {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })

      this.$router.push({
        name: 'unit.overview',
        params: {
          id: this.unitID,
          ownerid: this.ownerID,
          previousownerid: this.ownerID
        }
      })

      this.$route.params.previousownerid = this.ownerID
      this.reRender()

      loadingComponent.close()
    }
  },

  resolveOwnerID() {
    const ownerID = _get(this.$route.params, 'ownerid', 0)
    const primaryOwnerID = _get(this, ['unit', 'primaryOwner', 'ownerID'], null)
    const resolvedOwnerID = ownerID > 0 ? ownerID : primaryOwnerID

    console.debug('Resolved ownerid=' + resolvedOwnerID)
    return resolvedOwnerID
  },

  reRender() {
    //Necessary for now to force other tabs to re-render
    this.$forceUpdate()
    this.$router.go(0)
  },

  async getArchRequests() {
    const hoaID = this.hoaID
    const ownerID = this.ownerID

    const { list: archReqs, successful, message } = await getAReqsList({
      params: {
        // required
        hoaID,

        // optional
        ownerID,

        pendingStatus: true,
        pendingNotVotedStatus: true,

        approvedStatus: false,
        deniedStatus: false
      }
    })
    if (!successful) {
      console.error(message)
      return
    }

    const { list: workOrders } = await workOrderList.dispatch('getWorkOrderList', {
      hoaId: hoaID,
      unitID: this.unitID
    })

    console.log({ workOrders })

    this.archReqs = archReqs
    this.workOrders = workOrders

    this.loading = false
  },
  async getBalanceOwed() {
    const hoaID = this.hoaID
    const ownerID = this.ownerID

    const { result, successful, message } = await getReceivableBalance({
      params: {
        // required
        hoaID,
        ownerID
      }
    })
    if (!successful) {
      console.error(message)
      return
    }

    let balanceAmount = '0'
    try {
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      })

      const possible = result.balanceAmount

      if (typeof possible === 'number') {
        balanceAmount = possible
        this.balanceOwed =
          balanceAmount < 0
            ? `${formatter
                .format(balanceAmount)
                .toString()
                .replace('-', '(')})`
            : formatter.format(balanceAmount)

        if (balanceAmount >= 0) {
          this.balanceLabel = 'Balance Owed'
        } else {
          this.balanceLabel = 'Balance'
        }
      }
    } catch (exception) {
      console.error(exception)
    }
  },
  async getLeases() {
    const unitID = this.unitID

    const { list, successful, message } = await listLeases({
      params: {
        hoaID: this.hoaId,
        unitID
      }
    })
    if (!successful) {
      console.error(message)
      return
    }

    if (list.length === 0) {
      this.activeLease = false
    }

    const now = new Date()
    for (let a = 0; a < list.length; a++) {
      const startDate = _get(list, [a, 'startDate'], '')
      const endDate = _get(list, [a, 'endDate'], '')

      const result = await isBetween({
        from: startDate,
        to: endDate,

        question: now
      })

      console.log({ result })

      if (result === true) {
        this.activeLease = true
        break
      }
      if (result === false) {
        this.activeLease = false
        break
      }
    }
  },

  async getUnitDetails({ unitID }) {
    const { unit, exception } = await getUnit({
      unitID
    })
    if (exception) {
      console.error(exception)
      return
    }

    this.signUpCode = _get(unit, ['primaryOwner', 'ownerSignupCode'], null)
  },

  async getSignUpCode() {
    const { code, successful, message } = await generateOwnerSignupCode()
    if (!successful) {
      if (typeof this.signUpCode !== 'number') {
        this.signUpCode = 'not found'
      }

      notifyProblem(message)
      return
    }

    this.signUpCode = code
  },

  revealSignUpCode() {
    this.showSignUpCode = !this.showSignUpCode
  },

  //
  async showArchRequests() {
    const unitID = _get(this, ['unit', 'unitID'], null)
    const primaryOwnerID = _get(this, ['unit', 'primaryOwnerID'], null)

    if (typeof unitID !== 'number') {
      notifyProblem('A problem occurred while attempting to navigate to the architectural requests')
      return
    }

    this.$router.push({
      path: `/properties/${unitID}/${primaryOwnerID}/${primaryOwnerID}/architectural`
    })
  },
  async showWorkOrders() {
    const unitID = _get(this, ['unit', 'unitID'], null)
    const primaryOwnerID = _get(this, ['unit', 'primaryOwnerID'], null)

    if (typeof unitID !== 'number') {
      notifyProblem('A problem occurred while attempting to navigate to the work orders')
      return
    }

    this.$router.push({
      path: `/properties/${unitID}/${primaryOwnerID}/${primaryOwnerID}/workOrders`
    })
  },
  async showLeases() {
    const unitID = _get(this, ['unit', 'unitID'], null)
    const primaryOwnerID = _get(this, ['unit', 'primaryOwnerID'], null)

    if (typeof unitID !== 'number') {
      notifyProblem('A problem occurred while attempting to navigate to the leases')
      return
    }

    this.$router.push({
      path: `/properties/${unitID}/${primaryOwnerID}/${primaryOwnerID}/leaseInformation`
    })
  }
}
