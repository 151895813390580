<template>
  <div
    mobile-gallery
    class="property-photo-gallery"
    :style="{
      position: 'relative',

      width: '100%',
      minHeight: '100px',

      paddingRight: '10px',
      paddingLeft: '6px',
      paddingTop: '16px',

      overflowY: 'scroll',
      whiteSpace: 'nowrap'
    }"
    role="region"
    tabindex="0"
    aria-label="property photo gallery"
  >
    <figure
      class="mobile-gallery-figure"
      v-for="(photo, index) in photos"
      :key="index"
      tabindex="0"
      :aria-label="'photo ' + (index + 1)"
      :style="{
        position: 'relative',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',

        width: '95px',
        height: '71px',

        ...(true ? { marginLeft: '10px' } : {}),

        overflow: 'hidden',
        borderRadius: '6px',

        cursor: 'pointer'
      }"
      @click="updatePhotoDisplay({ photo, index })"
    >
      <transition name="fade">
        <img
          class="mobile-gallery-img"
          :src="photo.src"
          :style="{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translateX(-50%) translateY(-50%)',

            flexShrink: '0',
            maxWidth: '100%',
            maxHeight: '100%',
            objectFit: 'contain'
          }"
        />
      </transition>

      <transition name="fade">
        <button
          v-if="editMode"
          :style="{
            position: 'absolute',
            top: '4px',
            left: '4px',
            width: '20px',
            height: '20px',
            outline: 0
          }"
          @click="confirmDeletePhoto(photo)"
        >
          <TrashCan
            :style="{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%'
            }"
          />
        </button>
      </transition>

      <transition name="fade">
        <button
          v-if="editMode"
          :style="{
            position: 'absolute',
            top: '4px',
            right: '4px',
            width: '20px',
            height: '20px',
            outline: 0
          }"
          @click="setPhotoAsPrimary(photo)"
        >
          <Star
            :style="{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%'
            }"
            :contour="photo.isPrimary ? theme.star.contour : theme.star.contour"
            :color="photo.isPrimary ? theme.star.primary.color : theme.star.nonPrimary.color"
          />
        </button>
      </transition>
    </figure>

    <b-loading :style="{}" :is-full-page="false" v-model="loading"></b-loading>
  </div>
</template>

<script>
import Star from '@/components/icons/Star'
import TrashCan from '@/components/icons/Trash-Can'
// import { mdiTrashCan } from '@mdi/js';

import { methods } from './keys/methods'

export default {
  components: {
    Star,
    TrashCan
  },
  props: {
    perspective: String,
    updatePhotoDisplay: Function,
    openUploadModal: Function,
    unitID: {
      type: [Number, String],
      default: null
    },
    editMode: Boolean,
    updateOccurred: Function,
    photos: {
      type: Array,
      default() {
        return []
      }
    },
    loading: Boolean
  },
  data() {
    return {
      theme: this.getTheme()
    }
  },
  methods
}
</script>

<style lang="scss" scoped>
.mobile-gallery-figure {
  position: relative;
}

.mobile-gallery-img {
  position: relative;
}

.card {
  box-shadow: 0 12px 18px 0 rgba(0, 0, 0, 0.05) !important;

  .card {
    border: 0 !important;
  }
}

div.property-photo-gallery {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #dcdcdc;
  }

  // gecko
  scrollbar-color: #dcdcdc #fff;
  // scrollbar-width: thin;
}
</style>
