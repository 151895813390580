<template>
  <PageContentLayoutOnly :aria-label="$t('emergencyContacts.title')" role="region">
    <div class="is-8">
      <div>
        <b-button
          type="is-primary"
          class="is-rounded"
          @click="loadAddEmergencyContact()"
          emergency-contact-addition-button
          :style="{ marginBottom: '20px' }"
          >Add Emergency Contact</b-button
        >
      </div>

      <ModernTable
        :tableLoading="true"
        :region="region"
        :filters="filters"
        :rows="rows"
        :columns="columns"
        :pagination="{
          perPage: 10
        }"
        condensed
        headerColor="#FFF"
      />
    </div>

    <Modal
      emergency-contact-addition-modal
      :toggle.sync="showForm"
      :styles="{
        modal: {
          height: '80vh',
          overflowY: 'auto'
        }
      }"
    >
      <template v-slot:header>{{
        `${action && action === 'add' ? 'Create' : 'Update'} Emergency Contact`
      }}</template>
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <transition name="fade" mode="out-in" appear>
          <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
            <fieldset>
              <div class="column is-12">
                <valid-input
                  type="hidden"
                  vid="emergencyContactID"
                  v-model="formData.emergencyContactID"
                  :style="{ visibility: 'hidden' }"
                />
                <valid-input
                  type="hidden"
                  vid="addressID"
                  v-model="formData.AddressID"
                  :style="{ visibility: 'hidden' }"
                />
                <valid-input
                  label="Name"
                  v-model="formData.name"
                  :message="formErrors.name"
                  style="width:50px !important;"
                  name="Name"
                  maxlength="100"
                  type="text"
                  vid="Name"
                  placeholder="Name"
                  spellcheck="true"
                  aria-label="Name"
                  class="is-small"
                  rules="max:100|required"
                />
              </div>
              <div class="column is-12">
                <b-checkbox
                  v-model="formData.foreignAddress"
                  @click.native="foreignAddress = !foreignAddress"
                  class="has-left-text"
                  aria-label="address is foreign"
                  >Foreign</b-checkbox
                >
              </div>
              <div class="column is-12">
                <valid-input
                  v-model="formData.address1"
                  :message="formErrors.address1"
                  style="width:50px !important;"
                  name="Address1"
                  label="Address Line 1"
                  maxlength="255"
                  vid="Address1"
                  placeholder="Address 1"
                  spellcheck="true"
                  aria-label="Address1"
                  class="is-small"
                  rules="max:255|required"
                />
              </div>
              <div class="column is-12">
                <valid-input
                  v-model="formData.address2"
                  :message="formErrors.address2"
                  style="width:50px !important;"
                  name="Address2"
                  label="Address Line 2"
                  maxlength="255"
                  vid="Address2"
                  placeholder="Address 2"
                  spellcheck="true"
                  aria-label="Address2"
                  rules="max:255"
                  class="is-small"
                />
              </div>
              <div class="column is-12" v-if="foreignAddress == true">
                <valid-input
                  style="width:50px !important;"
                  name="Address3"
                  label="Address Line 3"
                  maxlength="255"
                  vid="Address3"
                  placeholder="Address 3"
                  spellcheck="true"
                  aria-label="Address3"
                  rules="max:255"
                  class="is-small"
                  v-model="formData.address3"
                  :message="formErrors.address3"
                />
              </div>
              <div class="column is-12" v-if="foreignAddress === false">
                <valid-input
                  style="width:50px !important;"
                  name="City"
                  label="City"
                  maxlength="100"
                  vid="City"
                  placeholder="City"
                  spellcheck="true"
                  aria-label="City"
                  class="is-small"
                  v-model="formData.city"
                  :message="formErrors.city"
                  rules="max:100|required"
                />
              </div>
              <div class="column is-12" v-if="statesList && foreignAddress === false">
                <valid-select
                  label="State"
                  placeholder="States"
                  vid="Region"
                  v-model="formData.region"
                  :message="formErrors.region"
                  rules="required"
                >
                  <option v-for="option in statesList" :value="option.value" :key="option.value">{{
                    option.label
                  }}</option>
                </valid-select>
              </div>
              <div class="column is-12" v-if="foreignAddress === false">
                <valid-input
                  style="width:50px !important;"
                  name="PostalCode"
                  label="Postal Code"
                  maxlength="10"
                  vid="PostalCode"
                  placeholder="Postal Code"
                  spellcheck="true"
                  aria-label="PostalCode"
                  class="is-small"
                  v-model="formData.postalCode"
                  :message="formErrors.postalCode"
                  rules="max:10|required"
                />
              </div>
              <div class="column is-12">
                <valid-input
                  v-model="formData.homePhone"
                  :message="formErrors.homePhone"
                  style="width:50px !important;"
                  name="HomePhone"
                  label="Home Phone"
                  maxlength="14"
                  type="phone"
                  vid="HomePhone"
                  placeholder="Home Phone (###) ###-####"
                  spellcheck="true"
                  aria-label="Home Phone"
                  rules="max:14"
                  class="is-small"
                  onkeypress="return (event.charCode >= 8 || event.charCode == 46) && event.charCode <= 57"
                />
              </div>
              <div class="column is-12">
                <valid-input
                  v-model="formData.cellPhone"
                  :message="formErrors.cellPhone"
                  style="width:50px !important;"
                  name="CellPhone"
                  label="Cell Phone"
                  maxlength="14"
                  type="phone"
                  vid="CellPhone"
                  placeholder="Cell Phone (###) ###-####"
                  spellcheck="true"
                  aria-label="CellPhone"
                  rules="max:14"
                  class="is-small"
                  onkeypress="return (event.charCode >= 8 || event.charCode == 46) && event.charCode <= 57"
                />
              </div>
              <div class="column is-12">
                <valid-input
                  v-model="formData.homeEmail"
                  :message="formErrors.homeEmail"
                  :rules="{
                    max: 255,
                    email: true
                  }"
                  style="width:50px !important;"
                  name="HomeEmail"
                  label="Home Email"
                  maxlength="255"
                  type="email"
                  vid="HomeEmail"
                  placeholder="Home Email Address"
                  spellcheck="true"
                  aria-label="HomeEmail"
                  class="is-small"
                />
              </div>
              <div class="column is-12" :style="{ textAlign: 'right' }">
                <button
                  modal-add-button
                  v-if="action && action == 'add'"
                  type="submit"
                  :disabled="loading"
                  class="button is-primary is-rounded"
                >
                  Add
                </button>
                <button
                  modal-update-button
                  v-if="action && action == 'update'"
                  @click.prevent="updateEmergencyContact(selectedEmergencyContact, selectedID)"
                  class="button is-primary is-rounded"
                >
                  Update
                </button>
              </div>
            </fieldset>

            <fieldset>
              <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                <div class="field">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </fieldset>
          </form>
        </transition>
      </ValidationObserver>
    </Modal>
  </PageContentLayoutOnly>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import kms from '@/services/kms'
import Modal from '@/components/Modal'
import ModernTable from '@/components/tables/Modern/Table'
import ValidInput from '@/components/inputs/ValidInput'
import ValidSelect from '@/components/inputs/ValidSelect'
import _get from 'lodash/get'
import { methods } from './keys/methods'
import { data } from './keys/data'
import { watch } from './keys/watch'

export const defaultDateFormat = 'MM/dd/yyyy'

export default {
  components: {
    Modal,
    ModernTable,
    PageContentLayoutOnly,
    ValidInput,
    ValidSelect,
    ValidationObserver,
    ValidationProvider
  },

  props: {
    // for the purpose of testing
    kms: {
      type: Object,
      default: () => {
        return kms
      }
    }
  },

  data,
  methods,

  created() {
    this.getRefreshed()
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    }),
    ...mapGetters('hoa', ['addressDropDownList']),
    ...mapGetters('unit', ['statesList'])
  },

  mounted() {
    this.ownerID = _get(this, '$store.state.hoa.unit.primaryOwner.ownerID', null)
    this.getRefreshed()

    this.$store.dispatch('hoa/loadDropDownAddressList')

    this.$store.dispatch('unit/getStatesList', {
      useAbbreviated: true
    })

    this.data = _get(this, ['addressDropDownList', 'dropDownAddresses'], null)

    this.resetForm()
  },

  watch,

  i18n: {
    messages: {
      en: { emergencyContacts: { title: 'Emergency Contacts' } }
    }
  }
}
</script>

<style lang="scss">
.my-grid-class {
  height: 400px;
}

tbody {
  min-height: 100px !important;
  overflow: auto !important;
}
</style>
