/*
	import { getReceivableBalance } from '@/services/AccountsReceivable/LedgerQueries/GetOverallBalance';

  const { result, successful, message } = await getReceivableBalance ({
    params: {
    	// required
			hoaID
			ownerID
    }
  });
  if (!successful) {
    console.error (message);
    return;
  }
*/

import kms from '@/services/kms'

export async function getReceivableBalance({ params }) {
  let message = 'The account receivable transactions list could not be retrieved.'

  try {
    const result = await kms.get(`/AccountsReceivable/LedgerQueries/GetOverallBalance`, {
      params
    })

    return {
      successful: true,
      message,
      result
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    successful: false,
    message,
    result: null
  }
}
