import { windowStore } from '@/utilities/window/store'

export function mounted() {
  this.refresh()
  this.refreshPrimaryPhoto()

  this.breakpoint = windowStore.state.breakpoint

  this.unsubWindowStore = windowStore.subscribe((mutation, state) => {
    const mType = mutation.type

    if (mType === 'breakPointChange') {
      this.breakpoint = state.breakpoint
    }
  })

  try {
    const tabName = this.$route.name.split('.')[1]
    console.log({ tabName }, this.$route.name)

    const tab = this.tabs.find(tab => {
      return tab.name === tabName
    })
    this.selectedTab = tab.label
  } catch (x) {
    console.error(x)
  }
}
