var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"details-section",style:({ position: 'relative' }),attrs:{"role":"main","aria-label":"Overview"}},[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-4"},[_c('label',{staticClass:"h6"},[_vm._v("Open Architectural Requests")]),_c('router-link',{staticClass:"button",style:({
          width: '190px',
          height: 'auto',
          display: 'block',
          wordBreak: 'normal',
          whiteSpace: 'normal'
        }),attrs:{"to":{ name: 'unit.architectural', params: { id: _vm.unitID } }}},[_vm._v(_vm._s(_vm.archReqs.length))])],1),_c('div',{staticClass:"column is-4"},[_c('label',{staticClass:"h6"},[_vm._v("Open Work Orders")]),_c('router-link',{staticClass:"button",style:({
          width: '190px',
          height: 'auto',
          display: 'block',
          wordBreak: 'normal',
          whiteSpace: 'normal'
        }),attrs:{"to":{ name: 'unit.workOrders', params: { id: _vm.unitID } }}},[_vm._v(_vm._s(_vm.workOrders.length))])],1),_c('div',{staticClass:"column is-4"},[_c('label',{staticClass:"h6"},[_vm._v("Currently Tenant Occupied")]),_c('div',{style:({
          display: 'flex',
          alignItems: 'center'
        })},[_c('h5',{style:({ display: 'inline-block', margin: 0, paddingRight: '10px' })},[(_vm.activeLease === true)?_c('span',[_vm._v("Yes")]):(_vm.activeLease === false)?_c('span',[_vm._v("No")]):_c('span',[_vm._v("?")])]),_c('router-link',{staticClass:"button",style:({
            display: 'inline-block',
            width: '190px',
            height: 'auto',
            wordBreak: 'normal',
            whiteSpace: 'normal'
          }),attrs:{"to":{ name: 'unit.leaseInformation', params: { id: _vm.unitID } }}},[_c('span',[_vm._v("Show Leases")])])],1)]),_c('div',{staticClass:"column is-4"},[_c('label',{staticClass:"h6"},[_vm._v(_vm._s(_vm.balanceLabel))]),_c('router-link',{staticClass:"button",style:({
          width: '190px',
          height: 'auto',
          display: 'block',
          wordBreak: 'normal',
          whiteSpace: 'normal'
        }),attrs:{"to":{ name: 'unit.accountsReceivable', params: { id: _vm.unitID } }}},[(typeof _vm.balanceOwed === 'string')?_c('span',[_vm._v(_vm._s(_vm.balanceOwed))]):(typeof _vm.balanceOwed === 'number')?_c('span',[_vm._v(_vm._s(_vm.balanceOwed))]):_c('span',[_vm._v("?")])])],1),_c('div',{staticClass:"column is-4"},[_c('label',{staticClass:"h6"},[_vm._v("Invite Code")]),_c('div',[_c('h5',{style:({ display: 'inline-block' })},[_vm._v(" "+_vm._s(_vm.showSignUpCode ? _vm.signUpCode : '*********')+" ")]),_c('button',{staticClass:"subheading button is-primary is-small is-outlined",style:({ display: 'inline-block', margin: '0 0 0 8px', borderRadius: '6px' }),on:{"click":_vm.revealSignUpCode}},[_vm._v(" "+_vm._s(_vm.showSignUpCode ? 'Hide' : 'Show')+" ")])])])]),_c('b-loading',{attrs:{"is-full-page":false},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }