<template>
  <div class="card-content" contact-details>
    <h3 :title="`Owner ID: ${primaryOwnerParsed.id}`">
      {{ primaryOwnerParsed.name || '' }}
    </h3>

    <ul v-if="lotNumber" class="mobile-labels">
      <li>
        <label class="h6 is-inline">Lot Number </label>
        <span>{{ lotNumber }}</span>
      </li>
    </ul>
    <ul v-if="primaryContactParsed" class="mobile-labels">
      <li v-if="primaryContactParsed.phone">
        <label class="h6 is-inline">Phone </label>
        <a :href="`tel:${primaryContactParsed.phone}`" :style="{ color: palette[1] }">{{
          primaryContactParsed.phone
        }}</a>
      </li>

      <li v-if="primaryContactParsed.cellPhone">
        <label class="h6 is-inline">Cell </label>
        <a :href="`tel:${primaryContactParsed.cellPhone}`" :style="{ color: palette[1] }">{{
          primaryContactParsed.cellPhone
        }}</a>
      </li>

      <li v-if="primaryContactParsed.workPhone">
        <label class="h6 is-inline">Work </label>
        <a :href="`tel:${primaryContactParsed.workPhone}`" :style="{ color: palette[1] }">{{
          primaryContactParsed.workPhone
        }}</a>
      </li>

      <li v-if="primaryContactParsed.emailAddressOne">
        <label class="h6 is-inline">Email </label>
        <a
          :href="`mailto:${primaryContactParsed.emailAddressOne}`"
          :style="{ color: palette[1] }"
          >{{ primaryContactParsed.emailAddressOne }}</a
        >
      </li>

      <li v-if="primaryContactParsed.emailAddressTwo">
        <label class="h6 is-inline">Secondary Email </label>
        <a
          :href="`mailto:${primaryContactParsed.emailAddressTwo}`"
          :style="{ color: palette[1] }"
          >{{ primaryContactParsed.emailAddressTwo }}</a
        >
      </li>

      <li v-if="primaryOwnerParsed.settlementDate">
        <label class="h6 is-inline">Owned Since </label>
        <a href="#" @click.stop :style="{ color: palette[1] }">{{
          primaryOwnerParsed.settlementDate | date
        }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
import _get from 'lodash/get'
import { parseE164 } from '@/utilities/phoneNumber/parseE164'

export default {
  props: {
    primaryContact: {
      type: Object,
      default: () => {
        return {}
      }
    },
    primaryOwner: {
      type: Object,
      default: () => {
        return {}
      }
    },
    unitID: [String, Number],
    lotNumber: {
      type: String,
      default: null
    },
    editMode: Boolean
  },
  computed: {
    primaryContactParsed() {
      const primaryContact = this.primaryContact

      return {
        phone: parseE164(_get(primaryContact, 'phone', '')),
        cellPhone: parseE164(_get(primaryContact, 'cellPhone', '')),
        workPhone: parseE164(_get(primaryContact, 'workPhone', '')),
        emailAddressOne: _get(primaryContact, 'emailAddressOne', ''),
        emailAddressTwo: _get(primaryContact, 'emailAddressTwo', '')
      }
    },
    primaryOwnerParsed() {
      const primaryOwner = this.primaryOwner

      return {
        name: _get(primaryOwner, 'name', ''),
        id: _get(primaryOwner, 'ownerID', ''),
        settlementDate: _get(primaryOwner, 'settlementDate', '')
      }
    }
  },
  data() {
    return {
      editModeData: this.editMode,
      palette: {
        1: '#2cabe1'
      }
    }
  },
  methods: {
    showEdit() {
      this.editModeData = !this.editModeData

      if (this.editModeData) {
        this.$emit('update:editMode', true)
      } else {
        this.$emit('update:editMode', false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/_variables';

.mobile-labels {
  .h6 {
    font-weight: normal !important;
  }
  a {
    font-weight: bold;
  }
}

.icon-container {
  top: 10px;
  right: 10px;

  height: 40px;
  width: 40px;

  .icon-pencil {
    height: 100%;
    width: 100%;
  }

  .icon-x {
    height: 50%;
    width: 50%;
  }

  &:focus {
    border-radius: 6px;
    box-shadow: $tabable-focus;
  }
}

.owner-avatar {
  margin: 1em auto 2em;
}

.avatar-img {
  border: 1px solid #e7e8f2;
  padding: 0.25em;
}
</style>
