<template>
  <PageContent>
    <div class="level">
      <div class="level-left level-item title"></div>
      <div class="level-right">
        <div class="level-item">
          <svg
            width="30"
            height="30"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 19l-7-7 7-7"
            ></path>
          </svg>
          <b-button label="Back to Properties" @click.prevent="returnToParent()" />
        </div>
      </div>
    </div>
    <section
      v-if="unit && breakpoint === 'desktop'"
      class="section card"
      :style="{
        overflow: 'visible',
        marginTop: '10px',
        ...(['tablet', 'mobile'].includes(breakpoint)
          ? {
              padding: 0
            }
          : {})
      }"
    >
      <div
        desktop-perspective
        class="details-top-container"
        :style="{
          display: 'flex',
          width: '100%',
          height: '600px',
          paddingTop: '12px',
          borderBottom: '1px solid #dcdcdc'
        }"
      >
        <div
          :style="{
            display: 'flex',
            width: '16%',
            height: '100%',
            maxWidth: '150px',
            minWidth: '90px'
          }"
          role="region"
          tabindex="0"
          aria-label="property photo gallery"
        >
          <Gallery
            ref="Gallery"
            :openUploadModal="openUploadModal"
            :updatePhotoDisplay="updatePhotoDisplay"
            :updateOccurred="galleryUpdateOccurred"
            :unitID="unitID"
            :editMode="editMode"
            :photos="photos"
            :loading="gallery.loading"
          />
        </div>

        <div
          :style="{
            display: 'flex',
            width: '100%',
            paddingLeft: '10px',
            height: '100%',
            flexWrap: 'wrap'
          }"
        >
          <div class="card" :style="{ height: '80%', width: '59%', marginRight: '1%' }">
            <PrimaryPhoto :src="primaryPhoto" :showIcon="false" />
          </div>

          <div class="card" :style="{ height: '80%', width: '40%', overflowY: 'auto' }">
            <ContactDetails
              role="region"
              tabindex="0"
              aria-label="contact details of the primary owner"
              :currentOwner="currentOwner"
              :primaryOwnerID="unit.primaryOwnerID"
              :primaryContact="primaryContact"
              :redirectToCurrentOwner="redirectToCurrentOwner"
              :unitID="unitID"
              :lotNumber="lotNumber"
            />
          </div>

          <div
            :style="{
              height: '20%',
              width: '100%'
            }"
          >
            <Address :address="unit.address" :unitID="unitID" />
          </div>
        </div>
      </div>

      <div id="tabs">
        <div class="card-content columns" :style="styles.mainContainer">
          <div class="column is-2" v-if="breakpoint === 'desktop'">
            <DetailsMenu
              aria-label="property details tabs"
              :tabs="tabs"
              prefix="unit."
              :params="{
                id: $route.params.id,
                ownerID
              }"
            />
          </div>

          <div class="column is-10" :style="{ overflow: 'hidden', position: 'relative' }">
            <SlideTransition :name="transitionName">
              <router-view />
            </SlideTransition>
          </div>
        </div>
      </div>
    </section>

    <div>
      <section
        v-if="unit && ['tablet', 'mobile'].includes(breakpoint)"
        class="section"
        :style="{
          overflow: 'visible',
          marginTop: '10px',
          padding: 0
        }"
      >
        <div mobile-tablet-perspective class="card">
          <PrimaryPhoto :src="primaryPhoto" :size="'300px'" />

          <MobileGallery
            ref="MobileGallery"
            :openUploadModal="openUploadModal"
            :updatePhotoDisplay="updatePhotoDisplay"
            :updateOccurred="galleryUpdateOccurred"
            :unitID="unitID"
            :editMode="editMode"
            :photos="photos"
            :loading="gallery.loading"
          />

          <div
            :style="{
              height: '20%',
              width: '100%'
            }"
          >
            <Address :address="unit.address" :unitID="unitID" />

            <div
              :style="{
                background: 'rgb(220, 220, 220)',

                margin: '0 12px',
                height: '1px',
                width: 'calc(100% - 24px)'
              }"
            ></div>

            <ContactDetailsMobile
              role="region"
              tabindex="0"
              aria-label="contact details of the primary owner"
              :currentOwner="currentOwner"
              :primaryContact="primaryContact"
              :unitID="unitID"
              :editMode.sync="editMode"
              :lotNumber="lotNumber"
            />

            <div
              :style="{
                background: 'rgb(220, 220, 220)',

                margin: '0 12px',
                height: '1px',
                width: 'calc(100% - 24px)'
              }"
            ></div>
          </div>
        </div>

        <div
          id="tabs"
          class="card"
          :style="{
            overflow: 'visible',
            position: 'relative',
            marginTop: '40px',
            padding: '20px'
          }"
        >
          <div
            :style="{
              background: palette[1],
              borderRadius: '50%',

              position: 'absolute',
              top: '-20px',
              left: '50%',
              transform: 'translateX(-50%)',

              height: '55px',
              width: '55px'
            }"
          >
            <div
              :style="{
                background: palette[2],
                borderRadius: '50%',

                position: 'relative',
                top: '5px',
                left: '5px',

                height: 'calc(100% - 10px)',
                width: 'calc(100% - 10px)'
              }"
            >
              <HouseFill
                :style="{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translateX(-50%) translateY(-50%) scale(1.2)'
                }"
              />
            </div>
          </div>

          <div class="column is-2" v-if="breakpoint === 'desktop'">
            <DetailsMenu
              aria-label="property details tabs"
              :tabs="tabs"
              prefix="unit."
              :params="{
                id: $route.params.id,
                ownerID
              }"
            />
          </div>

          <div :style="{ overflow: 'hidden', position: 'relative', padding: '2em 20px 2em' }">
            <b-field v-if="['tablet', 'mobile'].includes(breakpoint)">
              <b-select
                rounded
                :style="{ maxWidth: '300px', width: '100%', margin: '0 auto' }"
                v-model="selectedTab"
              >
                <option v-for="(key, index) in tabs" :key="index">
                  {{ key.label }}
                </option>
              </b-select>
            </b-field>
          </div>

          <div :style="{ overflow: 'hidden', position: 'relative', minHeight: '600px' }">
            <SlideTransition :name="transitionName">
              <router-view />
            </SlideTransition>
          </div>
        </div>

        <b-loading :is-full-page="false" v-model="loading"></b-loading>
      </section>
    </div>

    <UploadModal :toggle.sync="showUploadModal" :unitID="unitID" :uploadComplete="uploadComplete" />
  </PageContent>
</template>

<script>
import _get from 'lodash/get'
import { mapState } from 'vuex'
import SlideTransition from '@/components/transitions/Slide.vue'
import DetailsMenu from '@/components/menus/Details.vue'
import PageContent from '@/containers/PageContent'
import UploadModal from './components/UploadModal'
import Gallery from './components/Gallery'
import MobileGallery from './components/MobileGallery'
import Address from './components/Address'
import ContactDetails from './components/ContactDetails'
import ContactDetailsMobile from './components/ContactDetailsMobile'
import PrimaryPhoto from './components/PrimaryPhoto'
import { data } from './keys/data'
import { methods } from './keys/methods'
import { mounted } from './keys/mounted'
import { watch } from './keys/watch'
import HouseFill from '@/components/icons/House-Fill'

export default {
  components: {
    ContactDetails,
    ContactDetailsMobile,
    DetailsMenu,
    PrimaryPhoto,
    Address,
    Gallery,
    MobileGallery,
    SlideTransition,
    PageContent,
    HouseFill,
    UploadModal
  },

  computed: {
    ...mapState({
      unit: state => state.hoa.unit
    }),

    debug() {
      return this.unit
    },

    lotNumber() {
      return _get(this, ['unit', 'lotNumber'], null)
    },

    primaryContact() {
      const primaryOwnerContacts = _get(this, ['unit', 'primaryOwner', 'ownerContacts'], null)
      if (
        primaryOwnerContacts !== undefined &&
        primaryOwnerContacts &&
        primaryOwnerContacts.length > 0
      ) {
        const owners = _get(this, ['unit', 'owners'], null)
        let primaryOwnerContactID = 0
        if (owners !== undefined && owners) {
          const primaryContact = owners.filter(f => f.primaryOwnerContactID !== null)
          console.debug('promaryContact===' + JSON.stringify(primaryContact))
          if (primaryContact[0] !== undefined && primaryContact[0]) {
            primaryOwnerContactID = primaryContact[0].primaryOwnerContactID
          }
        }
        const contact = primaryOwnerContacts.filter(f => f.ownerContactID === primaryOwnerContactID)
        console.debug('primaryOwnerContacts===' + JSON.stringify(primaryOwnerContacts))
        if (contact[0] !== undefined && contact[0]) {
          const primaryContact = contact[0]
          return primaryContact
        }
      }

      return _get(this, ['unit', 'primaryOwner', 'ownerContacts', 0], null)
    }
  },

  data,
  watch,
  methods,
  mounted,

  i18n: {
    messages: {
      en: { units: { detailsTitle: 'Units Details' } }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/_variables';

.details-top-container {
  @media screen and (max-width: $desktop) {
    flex-direction: column;
    height: auto;
  }
}

.owner-avatar {
  margin: 1em auto 2em;
}

.card {
  box-shadow: 0 12px 18px 0 rgba(0, 0, 0, 0.05) !important;

  .card {
    border: 0 !important;
  }
}

.avatar-img {
  border: 1px solid #e7e8f2;
  padding: 0.25em;
}

.address-card {
  h5,
  h6 {
    margin: 0;
  }
}

.details-img {
  img {
    border-radius: 0.5em;
  }
}
</style>
